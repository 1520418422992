import styled from "styled-components";

export const MainContainer = styled.div`
    padding: 16px 25px;
    .show {
        display: block;
    }
    .hidde {
        display: none;
    }
    .required-data {
        color: #f44336;
        margin-top: 10px;
        display: block;
        font-size: 0.9rem;
    }
    .image-rounded-sm {
        border: 2px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 10px;
        overflow: hidden;
    }

    .image-rounded-sm img {
        cursor: pointer;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .image-rounded-sm > input {
        display: none;
    }

    .list-font-small li {
        font-size: 0.8rem;
    }
    .list-style-disc {
        padding: 0px;
    }
    .list-style-disc li {
        list-style-type: disc;
        display: list-item;
        padding-left: 0;
        list-style-position: inside;
    }
`;

export const SuccessMessage = styled.div`
    text-align: center;
    padding: 30px;
    .icon {
        font-size: 3rem;
    }
    .actions {
        display: flex;
        grid-gap: 15px;
        align-items: center;
        justify-content: center;
    }
`;

export const ProductVariantsFormContainer = styled.div`
    display: flex;
    flex-direction: column;

    .options-header {
        display: flex;
        margin-bottom: 10px;
        .title {
            min-width: 220px;
            font-weight: 500;
        }
    }
    .variant-options {
        display: flex;
        flex-direction: column;
    }
`;

export const ProductVariantCardListContainer = styled.div`
    .variant-card:last-child {
        border: 0px;
    }
`;

export const ProductVariantOptionCard = styled.div`
    display: flex;
    margin-bottom: 15px;
    .option {
        padding-right: 15px;
    }
    .values {
        width: 100%;
    }
`;

export const ProductVariantCardContainer = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 0px;
    padding: 10px;
    display: flex;

    .image {
        cursor: pointer;
        width: 60px;
        min-width: 60px;
        height: 60px;
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        background: ${(props) => props.theme.palette.backgroundLight}20;
        margin-right: 10px;
        overflow: hidden;
        border-radius: 10px;
        position: relative;

        .hover-edit {
            display: none;
        }

        &:hover {
            .hover-edit {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: ${(props) => props.theme.palette.background}60;
                font-size: 1.5rem;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .body {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .edit {
        padding-left: 8px;
        display: flex;
        align-items: flex-end;
        padding-bottom: 7px;
    }

    .options {
        width: 100%;
        display: flex;
        .label {
            font-size: 1rem;
            margin-right: 8px;
            margin-bottom: 5px;
            border-radius: 50px;
            color: ${(props) => props.theme.palette.textLight};
            .bold {
                font-weight: 500;
                margin-left: 3px;
                color: ${(props) => props.theme.palette.text};
            }
        }
    }
    .inputs {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-auto-rows: 1fr;

        .input-group {
            display: flex;
            background: ${(props) => props.theme.palette.backgroundLight};
            border-radius: 10px;
            overflow: hidden;
            align-items: center;

            &.hover-pointer {
                cursor: pointer;
            }

            .label {
                color: ${(props) => props.theme.palette.textLight};
                font-size: 0.9rem;
                padding-right: 0px;
                padding-left: 8px;
            }

            input {
                width: 100%;
                border-radius: 0px;
                background: transparent;
                border: 0;
                &:focus {
                    border-color: tranparent;
                }
            }
        }
    }
`;

export const ProductVariantPricesContainer = styled.div`
    padding: 20px 33px;
`;

export const ImagesSampleView = styled.div`
    display: flex;
    width: 100%;
    overflow: auto;
`;
export const ProductComponentRowContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    .quantity {
        display: flex;
        align-items: center;
        margin-left: auto;
        button {
            background: transparent;
            border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        }
        input {
            text-align: center;
            border-radius: 40px;
            margin-right: 10px;
            margin-left: 10px;
        }
    }
    .content {
        display: flex;
        flex-direction: column;
    }
    .light {
        color: ${(props) => props.theme.palette.textLight};
    }
    .image {
        width: 50px;
        min-width: 50px;
        height: 50px;
        overflow: hidden;
        margin-right: 10px;
        border-radius: 10px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`;

export const DragImageCardContainer = styled.div`
    background: transparent;
    border-radius: 6px;
    user-select: none;
    padding: 8px;
    ${(props: { isDragging: any }) => (props.isDragging ? "background: #2196f32e" : "")}
`;

export const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

export const ActionButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    margin-bottom: 10px;
`;

export const FlexFormContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FormInput = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    input {
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        background: ${(props) => props.theme.palette.background};
        border-radius: 50px;
        min-width: 120px;
    }

    &.small-input3 {
        input {
            max-width: 127px;
        }
    }

    &.small-input4 {
        input {
            min-width: 88px;
        }
    }

    textarea {
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        background: ${(props) => props.theme.palette.background};
    }

    label {
        color: ${(props) => props.theme.palette.textLight};
        font-size: 0.95rem;
        margin-bottom: 4px;
    }
    .mb4 {
        margin-bottom: 4px;
    }
    .label-category {
        color: ${(props) => props.theme.palette.textLight};
        font-size: 0.95rem;
    }
`;

export const CategoryCardOption = styled.div`
    display: flex;
    padding: 2px;
    align-items: center;
    background: ${(props) => props.theme.palette.backgroundLight};
    margin-bottom: 7px;
    border-radius: 37px;

    .not-category {
        font-size: 0.9rem;
        color: ${(props) => props.theme.palette.textLight};
    }

    max-width: 226px;
    justify-content: space-between;
    padding: 5px 15px;
`;

export const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const InputNumberContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .textbox-numeral {
        margin-right: 10px;
    }
    span {
        width: 120px;
    }
    input {
        width: 100%;
    }
`;

export const RowResponsiveMin = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(85px, 1fr));
    grid-auto-rows: 1fr;
`;

export const AddImageFlexView = styled.div`
    display: flex;
    align-items: center;
    margin-top: 7px;
    justify-content: center;

    .card {
        width: 50px;
        height: 50px;
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 39px;
        margin-left: 10px;
        .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &.small {
        .card {
            width: 150px;
            height: 150px;
        }
    }
`;

export const AllImageContainer = styled.div`
    border-radius: 9px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    transition: background 0.2s;
`;

export const ImageViewCard = styled.div`
    height: 120px;
    width: 120px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    iframe {
        border: 0px;
        width: 100%;
        height: 100%;
    }
    .imagecontainer {
        width: 100%;
        height: 100%;
    }
    .remove {
        position: absolute;
        bottom: -56px;
        left: calc(50% - 15px);
        transition: bottom 0.2s;
        background: ${(props) => props.theme.palette.background};
        color: ${(props) => props.theme.palette.text};
        box-shadow: 1px 1px 20px #020202d9;
    }
    &:hover {
        .remove {
            bottom: 6px;
        }
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const TaxContainerView = styled.div`
    margin-top: 25px;
    padding-top: 20px;
    border-top: 1px solid #b9b9b92b;

    .header {
        display: flex;
    }
    .dropbtn {
        border-radius: 40px;
    }
    .taxtitle {
        font-size: 0.9rem;
        margin-bottom: 7px;
        color: ${(props) => props.theme.palette.textLight};
    }
    .taxcontainer {
        display: flex;
        flex-direction: column;

        .tax-item {
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-bottom: 6px;
            align-items: center;
            /* padding: 4px; */
            padding-left: 16px;
            border: 1px solid ${(props) => props.theme.palette.backgroundDark};
            border-radius: 28px;
            box-sizing: border-box;

            color: ${(props) => props.theme.palette.textLight};
            font-weight: 500;
            background: ${(props) => props.theme.palette.backgroundDark};

            button {
                background: transparent;
                &:hover {
                    background: ${(props) => props.theme.palette.backgroundDarker};
                    color: ${(props) => props.theme.palette.text};
                }
            }
        }
    }
    .btntax {
        background: ${(props) => props.theme.palette.primary};
        border: 0px;
        color: white;
    }
`;
