import appconfig from "./appconfig";
const wahioFetchApi = require("axios");

wahioFetchApi.default.headers = {
    accept: "application/json",
    "content-type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
};

wahioFetchApi.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
wahioFetchApi.defaults.headers.common["Ocp-Apim-Subscription-Key"] = appconfig.OCP_APIM_SUBSCRIPTION_KEY;

export const setDefaultToken = (token: string) => {
    wahioFetchApi.defaults.headers.common["Authorization"] = `bearer ${token}`;
};

export const getFetchError = (e: any) => {
    if (e.response) {
        return e.response;
    }
    return {
        status: 500,
        message: e.error,
    };
};

export default wahioFetchApi;
