import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import EmptyImage from "../../assets/images/picture-image.png";
import ProductDetails from "./ProductDetails";
import LoadingDualRing from "../LoadingDualRing";
import messages from "./messages";
import { IProduct, IProductVariant } from "../../store/types/product";
import { useParams } from "react-router-dom";
import { wahioFetch } from "../../services";
import { productEndpoint } from "../../services/restApiEndpoint";
import { ContainerBody, HeaderImage, ImageContainer, ProductGlobalContainer } from "./styled";
import ViewContainer from "../ViewContainer";
import ProductImagesSlider from "./ProductImagesSlider";
import DefaultImage from "../../assets/images/picture-image.png";
import { WahioFile, WahioFileImpl } from "../../store/types";
import { ProductComponentRowContainer } from "../ProductCreationForm/styled";
import WahioHeader from "../WahioHeader";
import { Helmet } from "react-helmet";
import WahioFooter from "../WahioHeader/WahioFooter";

var parse = require("html-react-parser");

export enum Stage {
    Detail,
    Images,
    Activity,
}

export interface IProductProfile {
    product: IProduct;
    productVariant?: IProductVariant;
    setProductVariant: (value?: IProductVariant) => void;
}

interface IProductParams {
    productId?: string;
}

const ProductProfile = () => {
    const intl = useIntl();
    const isMounted = React.useRef(true);
    const [productVariant, setProductVariant] = useState<IProductVariant | undefined>();
    const [product, setProduct] = useState<IProduct | undefined>(undefined);
    const [isFetching, setIsFetching] = useState(false);
    const [productFetchCompleted, setProductFetchCompleted] = useState(false);
    const { productId } = useParams<IProductParams>();

    useEffect(() => {
        if (productId) {
            fetchProductById(productId);
        }

        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId]);

    const fetchProductById = (id: string) => {
        if (!isMounted.current) return;
        setIsFetching(true);
        return wahioFetch.get(
            productEndpoint.get.product(id),
            (success) => {
                const product: IProduct = success.data;
                if (isMounted.current) {
                    setProduct(product);
                    setProductFetchCompleted(true);
                    setIsFetching(false);
                }
            },
            (error) => {
                isMounted.current && setIsFetching(false);
            }
        );
    };

    if (!product && isFetching) return <LoadingDualRing center={true} />;

    if (!product) {
        return null;
    }

    const getImages = (): WahioFile[] => {
        if (productVariant) {
            if (productVariant.images && productVariant.images.length > 0) {
                return productVariant.images;
            } else {
                return [];
            }
        }
        if (product.includesVariants && product.variants && product.variants.length > 0) {
            return product.variants.map((item) => new WahioFileImpl(item.image, "image"));
        }
        return product.images ?? [];
    };
    const images = getImages();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{product.name} - Wahio</title>
            </Helmet>
            <ViewContainer size="md">
                <WahioHeader />
                <ProductGlobalContainer>
                    <ContainerBody>
                        {images && images.length > 1 ? (
                            <ProductImagesSlider images={images} />
                        ) : (
                            <HeaderImage>
                                <ImageContainer>
                                    <img
                                        className="object-fit-cover"
                                        src={images.length > 0 ? images[0].url : EmptyImage}
                                        alt={product.name}
                                    />
                                </ImageContainer>
                            </HeaderImage>
                        )}
                        <ProductDetails
                            productVariant={productVariant}
                            setProductVariant={setProductVariant}
                            setOpenEdit={() => {}}
                            fetchCompleted={productFetchCompleted}
                            product={product}
                        />
                    </ContainerBody>
                    {isFetching && <LoadingDualRing center={true} />}

                    {product.childs?.length > 0 && (
                        <div className="mb-1">
                            <h3>{intl.formatMessage(messages.productComposition)}</h3>
                            {product.childs?.map((item, index) => (
                                <ProductComponentRowContainer key={index}>
                                    <div className="image">
                                        <img src={item.child?.profilePicture ?? DefaultImage} alt={item.child?.name} />
                                    </div>
                                    <div className="content">
                                        <span>{item.child?.name}</span>
                                        <span className="light">{item.child?.sku}</span>
                                    </div>
                                    <div className="quantity mr-1">
                                        <span>{item.quantity}</span>
                                    </div>
                                </ProductComponentRowContainer>
                            ))}
                        </div>
                    )}
                    <div>
                        {product.description && (
                            <>
                                <div className="mt-2">{parse(product.description)}</div>
                            </>
                        )}
                    </div>
                </ProductGlobalContainer>
                <WahioFooter />
            </ViewContainer>
        </>
    );
};
export default ProductProfile;
