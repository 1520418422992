export const StoreKey = {
    appSettings: "appSettings",
    todo: "todos",
    accountUser: "accountUser",
    product: "product",
    warehouseProduct: "warehouseProduct",
    organization: "organization",
    currentSale: "currentSale",
    currentPurchase: "currentPurchase",
    productCategory: "productCategory",
    seller: "seller",
    customer: "customer",
    warehouse: "warehouse",
    sale: "sale",
    lounge: "lounge",
    deliveryOrder: "deliveryOrder",
    purchase: "purchase",
    supplier: "supplier",
    customCategory: "customCategory",
    productCompany: "productCompany",
    productCatalog: "productCatalog",
    invoiceDesign: "invoiceDesign",
    inventoryAdjustment: "inventoryAdjustment",
    tax: "tax",
    discount: "discount",
    orderChannel: "orderChannel",
    expense: "expense",
    expenseCategory: "expenseCategory",
    warehouseMovement: "warehouseMovement",
    cashRegister: "cashRegister",
    cashRegisterLog: "cashRegisterLog",
    warehouseLocation: "warehouseLocation",
    orderInvoiceNumeration: "orderInvoiceNumeration",
};

export const persistOptions = [StoreKey.appSettings, StoreKey.currentPurchase, StoreKey.sale, StoreKey.product, StoreKey.currentSale];

const PREFIX = "wahioapp";

const checkKey = (key: string) => {
    return persistOptions.find((x) => x === key);
};

const getKeyName = (keyName: string) => {
    return PREFIX + keyName;
};

export function getStoredData<T>(defaultState: T, keyName: string): T {
    if (!checkKey(keyName)) return defaultState;
    let storeItem = localStorage.getItem(getKeyName(keyName)) as string;
    storeItem = decodeValue(storeItem);
    if (storeItem) {
        try {
            const localData: T = JSON.parse(storeItem);
            return localData;
        } catch (e) {
            console.error(`Invalid storage value for ${keyName}`);
        }
    }
    return defaultState;
}

export function saveData<T>(keyName: string, obj: T) {
    if (checkKey(keyName)) {
        localStorage.setItem(getKeyName(keyName), encodingObject(obj));
    }
}

export function deleteData(keyName: string) {
    if (checkKey(keyName)) localStorage.removeItem(getKeyName(keyName));
}

function encodingObject(obj: any): string {
    let stringifyObject = JSON.stringify(obj);
    try {
        return btoa(stringifyObject);
    } catch (error) {
        try {
            return btoa(unescape(encodeURIComponent(stringifyObject)));
        } catch (error) {
            return stringifyObject;
        }
    }
}

function decodeValue(value: string) {
    try {
        return atob(value);
    } catch (error) {
        try {
            return decodeURIComponent(escape(atob(value)));
        } catch (error) {
            return value;
        }
    }
}
