import styled from "styled-components";
import { horMenuHeight } from "../../../constants/defaultValues";
import { breakpoint } from "../../../theme/themeProvider";
import SearchInputTimer from "../inputs/SearchInputTimer";

export const RelativeContainer = styled.div`
    position: relative;
`;

export const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;

    ${breakpoint("md")`
        width: auto;
        flex-wrap: wrap;
    `}
`;

export const FlexContainerParent = styled(FlexContainer)`
    flex-wrap: wrap;
    width: 100%;
`;

export const FlexContainerColumn = styled.div`
    display: flex;
    flex-direction: column;
`;
export const Title = styled.span`
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 6px;
`;
export const ToolbarView = styled.div`
    position: sticky;
    top: ${horMenuHeight};
    display: flex;
    padding: 10px 10px;
    background: ${(props) => props.theme.palette.background};
    flex-direction: column;

    &.border-none {
        border: 0px;
    }
    flex-wrap: wrap;
    z-index: 2;

    .filter-options {
        display: none;
        &.show {
            display: flex;
        }
    }

    ${breakpoint("md")`
    .filter-options {
        display: flex;
    }
`}
`;

export const ToolbarItem = styled.div`
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &.mobile-width {
        .search-input {
            max-width: 100%;
        }
        width: 100%;
    }
    .filter {
        display: flex;
    }

    ${breakpoint("md")`
    .filter {
        display: none;
    }
    &.mobile-width {
        .search-input {
            max-width: 400px;
        }
        width: auto;
    }
`}
`;

export const FlexSpaceBetween = styled(FlexContainer)`
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
`;

export const SearchCustom = styled(SearchInputTimer)`
    border-radius: 50px;
`;

export const AllContainer = styled.div`
    .btn-container {
        button {
            min-width: 25px;
            width: 25px;
            height: 25px;
        }
    }
    .dropbtn {
        border-radius: 50px;
    }
    .light {
        font-size: 0.95rem;
        color: ${(props) => props.theme.palette.textLight};
    }
    .pright {
        padding-left: 10px;
        padding-right: 15px;
    }
    .style-neutral {
        background: ${(props) => props.theme.palette.backgroundDark};
        color: ${(props) => props.theme.palette.textLight};
        border-color: transparent;
    }
    .style-orange {
        background: #ff99002d;
        color: #ff9800 !important;
        border-color: transparent;
    }
    .style-blue {
        background: #2962ff2c;
        color: #2962ff;
        border-color: transparent;
    }
    .style-red {
        background: #f443362d;
        color: #f44336;
        border-color: transparent;
    }
    .style-green {
        background: #00968838;
        color: #009688;
        border-color: transparent;
    }
    .res-active {
        background: transparent;
        color: ${(props) => props.theme.palette.textLight};
        border-color: transparent;
    }

    .decorator {
        border-radius: 22px;
        color: ${(props) => props.theme.palette.textLight};
        display: inline-flex;
        padding: 1px 11px;
        align-items: center;
        font-size: 0.9rem;
        grid-gap: 6px;
        &.active {
            background: ${(props) => props.theme.palette.backgroundLight};
            span {
                color: #2962ff;
            }
        }
        span {
            font-size: 0.8rem;
        }
    }
`;
