import React, { useContext } from "react";
import { AppSettingsContext } from "../../appSettings/AppSettingsContext";
import { Container } from "./styled";

export interface ViewContainerProps {
    border?: boolean;
    size?: "sm" | "md" | "lg";
    children: React.ReactNode;
    padding?: number;
}

export default function ViewContainer(props: ViewContainerProps) {
    const { size = "sm", padding = 10 } = props;
    const { appSettingsState } = useContext(AppSettingsContext);
    return (
        <Container padding={padding} menuSmall={appSettingsState.menuSmall} className={`size-${size}`} withBorder={props.border}>
            {props.children}
        </Container>
    );
}
