import React, { useState } from "react";
import { DefaultInput, PrimaryButton } from "../../components/_controls";
import { HomeContainerCenter, HomeBody, TrackingContainer } from "./styled";
import TrackingImage from "../../assets/images/tracking.svg";
import WahioHeader from "../../components/WahioHeader";
import { Redirect } from "react-router-dom";
import PATHS from "../../constants/paths";

export default function Home() {
    const [state, setState] = useState({
        productId: "",
        showTrackingPage: false,
    });

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setState({ ...state, showTrackingPage: true });
    };

    if (state.showTrackingPage) {
        return <Redirect to={PATHS.productProfileId(state.productId)} />;
    }

    const disabledsearch = state.productId.length < 4;
    return (
        <HomeContainerCenter>
            <WahioHeader />
            <HomeBody>
                <div className="image">
                    <img src={TrackingImage} alt="Wahio Tracking" />
                </div>

                <div className="title-container">
                    <h2 className="title">Order Product</h2>
                    <p className="content">Ingrese el ID del producto</p>
                </div>
                <TrackingContainer className={disabledsearch ? "" : "active"} onSubmit={handleSubmit}>
                    <DefaultInput
                        required
                        minLength={4}
                        onChange={(e) => setState({ ...state, productId: e.target.value })}
                        placeholder="ID del producto"
                    />
                    <PrimaryButton type="submit" disabled={disabledsearch}>
                        Buscar
                    </PrimaryButton>
                </TrackingContainer>
            </HomeBody>
        </HomeContainerCenter>
    );
}
