import appSettings from "../appsettings.json";

const getValue = (name: string) => {
    var result = (appSettings as any)[name];
    if (result) return result;
    throw new Error(`The attribute with the name [${name}] was not found`);
};

export const API_GATEWAY_ENDPOINT = getValue("apiGatewayEndpoint");
export const OCP_APIM_SUBSCRIPTION_KEY = getValue("ocpApimSubscriptionKey");
export const SOCKETIO_SERVER_ENDPOINT = getValue("socketioEndpoint");
export const APP_VERSION = getValue("appVersion");

const PAGINATION = getValue("pagination");

const getPagenationValue = (name: string) => {
    var result = PAGINATION[name];
    if (result) return result;
    throw new Error(`The attribute with the name [${name}] was not found`);
};

export const PURCHASE_PAGE_SIZE = getPagenationValue("purchaseSize");
export const PRODUCT_PAGE_SIZE = getPagenationValue("productSize");

export default {
    API_GATEWAY_ENDPOINT,
    OCP_APIM_SUBSCRIPTION_KEY,
};
