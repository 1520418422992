import styled from "styled-components";

export const DefaultInput = styled.input`
    padding: 0.6rem 1rem;
    border: 1px solid transparent;
    outline-style: none;
    color: ${(props) => props.theme.palette.text};
    background: ${(props) => props.theme.palette.backgroundLight};
    font-size: 1rem;
    box-sizing: border-box;

    border-radius: 5px;
    &:focus {
        border-color: ${(props) => props.theme.palette.backgroundDark};
    }
`;

export const DefaultTextarea = styled.textarea`
    padding: 0.6rem 1rem;
    border: 1px solid transparent;
    outline-style: none;
    color: ${(props) => props.theme.palette.text};
    background: ${(props) => props.theme.palette.backgroundLight};
    font-size: 1rem;
    box-sizing: border-box;
    border-radius: 5px;
    &:focus {
        border-color: ${(props) => props.theme.palette.backgroundDark};
    }
`;

export const InputSearchCustom = styled.input`
    padding: 0.6rem 1rem;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    outline-style: none;
    color: ${(props) => props.theme.palette.text};
    background: ${(props) => props.theme.palette.background};
    font-size: 1rem;
    &:focus {
        box-shadow: 0px 0px 4px #2196f369;
        border-color: ${(props) => props.theme.palette.primary};
    }
`;
