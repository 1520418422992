import {
    AppSettingsTypes,
    CHANGE_THEME,
    THEME_LIGHT,
    THEME_DARK,
    SET_LANGUAGE,
    SET_LIST_PRODUCT_SHAPE,
    SET_DEFAULT_PRICE,
    SET_DEFAULT_ORGANIZATION,
    CHANGE_MENU_SMALL,
    SET_DEFAULT_WAREHOUSE,
    SET_DEFAULT_LOUNGE,
    SET_DELIVERY_SUMMARY,
    IAppSettingsState,
    initStateBase,
    SET_ALL_SETTINGS,
} from "./types";
import {getStoredData,StoreKey} from '../store/index';

const getInitialState = () => {
    
    var existing: IAppSettingsState = getStoredData<IAppSettingsState>(initStateBase,StoreKey.appSettings);
  
    if (existing) {
        if (!existing.deliverySummary) {
            existing.deliverySummary = initStateBase.deliverySummary;
        }
        return existing;
    }
    return initStateBase;
};

export const initState: IAppSettingsState = getInitialState();

const loadNewTheme = (state: IAppSettingsState): IAppSettingsState => {
    const newTheme = state.theme === THEME_LIGHT ? THEME_DARK : THEME_LIGHT;
    return { ...state, theme: newTheme };
};

export const appSettingsReducer = (state: IAppSettingsState, action: AppSettingsTypes): IAppSettingsState => {
    switch (action.type) {
        case SET_LIST_PRODUCT_SHAPE:
            return {
                ...state,
                productListShape: action.shape,
            };
        case CHANGE_THEME:
            return loadNewTheme(state);
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.value,
            };
        case SET_DEFAULT_PRICE:
            return {
                ...state,
                defaultPrice: action.value,
            };
        case SET_DEFAULT_ORGANIZATION:
            return {
                ...state,
                defaultOrganizationId: action.value,
            };
        case SET_DEFAULT_WAREHOUSE:
            return {
                ...state,
                defaultWarehouseId: action.value,
            };
        case SET_DEFAULT_LOUNGE:
            return {
                ...state,
                defaultLoungeId: action.value,
            };
        case SET_DELIVERY_SUMMARY:
            return {
                ...state,
                deliverySummary: action.value,
            };
        case CHANGE_MENU_SMALL:
            return {
                ...state,
                menuSmall: !state.menuSmall,
            };
        case SET_ALL_SETTINGS:
            return action.value;

        default:
            return state;
    }
};
