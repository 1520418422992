import styled from "styled-components";
import { breakpoint } from "../../theme/themeProvider";
import { TableTr } from "../_controls/tables/styled";

export const ContainerBody = styled.div`
    padding: 10px;

    display: flex;
    flex-direction: column;
    margin-top: 0px;

    ${breakpoint("lg")`
       flex-direction: row;
    `}

    .edit {
        background: ${(props) => props.theme.palette.backgroundDark};
        border-radius: 50px;
    }

    h1 {
        margin: 0px;
        margin-top: 1rem;
    }

    .mb-1 {
        margin-bottom: 10px;
    }

    .dates {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        span {
            color: ${(props) => props.theme.palette.textLight};
            font-size: 0.9rem;
        }
    }
`;

export const ProductGlobalContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    border: 0px;
    padding-bottom: 2rem;
    margin-bottom: 1rem;
    .light {
        font-size: 0.9rem;
        color: ${(props) => props.theme.palette.textLight};
    }
    ${breakpoint("md")`
    margin-top: 15px;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    `}

    ${breakpoint("sm")`
        padding: 20px 25px;
        padding-top: 10px;
    `}
`;

export const CompositionContainer = styled.div`
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding: 5px 10px;
    padding-top: 30px;
    .title-category {
        margin: 0px;
        font-weight: 300;
    }
    .icon {
        background: transparent;
        margin-right: 15px;
        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
`;

export const CodesContainer = styled.div`
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding: 5px 10px;
    .title-category {
        margin: 0px;
        font-weight: 300;
    }
    .icon {
        background: transparent;
        margin-right: 15px;
        &:hover {
            background: ${(props) => props.theme.palette.backgroundDark};
        }
    }
`;

export const ProductWarehouseSummaryContainerBody = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
`;

export const ProductWarehouseSummaryContainer = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-direction: column;
    padding: 16px 1px;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.palette.backgroundLight};
    padding: 15px;
    .icon-row {
        display: flex;
        align-items: center;
        margin-top: 5px;
        .icon {
            width: 22px;
            height: 22px;
            font-size: 0.8rem;
            background: blue;
            border-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 5px;
            background: ${(props) => props.theme.palette.primary}20;
            color: ${(props) => props.theme.palette.primary};
        }
    }
    .content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
    }

    .subcontent {
        display: flex;
        flex-direction: column;
        font-size: 0.9rem;
        margin-right: 15px;
        color: ${(props) => props.theme.palette.textLight};
    }
    .warehouse-title {
        margin: 0px;
        color: ${(props) => props.theme.palette.text};
        font-size: 1rem;
        margin-bottom: 2px;
    }

    .location-grid {
        margin-top: 15px;
        display: grid;
        width: 100%;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    }

    .serials {
        .serial-title {
            color: ${(props) => props.theme.palette.textLight};
            margin: 10px 0px;
            display: block;
        }
        ul {
            margin: 0px;
            padding: 0px;
        }
        ul li {
            list-style: none;
            color: ${(props) => props.theme.palette.text};
        }
    }
`;

export const HeaderImage = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    padding-top: 12px;
    padding-bottom: 0px;
    margin-right: 0px;
    align-items: center;
    ${breakpoint("lg")`
    margin-right: 20px;
    align-items: baseline;
`}
`;

export const ImageContainer = styled.div`
    height: 400px;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    max-width: 450px;
    position: relative;

    .background {
        position: absolute;
        top: -20px;
        left: -17px;
        width: 120%;
        height: 120%;
        z-index: 0;
        opacity: 0.5;
        filter: blur(8px);
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    iframe {
        border: 0px;
        width: 100%;
        height: 100%;
    }

    ${breakpoint("lg")`
        height: 250px;
        width: 250px;
       
    `}

    ${breakpoint("xl")`
        height: 400px;
        width: 400px;
        max-width: auto;
    `}

    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        position: relative;
    }
`;

export const ImageListContainer = styled.div`
    display: flex;
    margin-top: 20px;

    max-width: 100%;
    overflow: auto;
    ${breakpoint("lg")`
        max-width: 250px;
    `}

    ${breakpoint("xl")`
        max-width: 400px;
    `}

    .img-body {
        border: 2px solid transparent;
        border-radius: 8px;
        width: 50px;
        min-width: 50px;
        height: 50px;
        overflow: hidden;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        padding: 1px;

        &.videoLink {
            background: ${(props) => props.theme.palette.backgroundDark};
        }

        iframe {
            border: 0px;
            width: 100%;
            height: 100%;
        }

        &.active {
            border-color: ${(props) => props.theme.palette.primary};
        }
        &:hover {
            border-color: ${(props) => props.theme.palette.primaryDark};
        }
    }
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 7px;
    }
`;

export const UlCustom = styled.ul`
    margin: 0px;
    padding: 0;
    margin-bottom: 15px;
    color: ${(props) => props.theme.palette.textLight};
    .title {
        color: ${(props) => props.theme.palette.text};
        margin-right: 5px;
    }
    li {
        display: flex;
    }

    .columns {
        display: flex;
        flex-direction: column;
    }
`;

export const FlexContainerWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .labelpair {
        margin-right: 15px;
        margin-bottom: 10px;
    }
`;

export const TaxesContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    color: ${(props) => props.theme.palette.textLight};
    ul {
        padding: 0px;
        margin: 0px;
        margin-top: 4px;
        li {
            list-style: circle;
        }
        color: ${(props) => props.theme.palette.text};
    }
`;

export const TaxItemView = styled.li`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    list-style: circle;
    .header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        button {
            width: 23px;
            height: 23px;
            min-width: 20px;
        }
    }

    .info {
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        background: ${(props) => props.theme.palette.backgroundLight};
        margin: 9px;
        border-radius: 10px;
        padding: 10px 15px;
        font-size: 0.9rem;
        color: ${(props) => props.theme.palette.textLight};
        display: flex;
        flex-direction: column;
        transition: padding 0.4s;
        &.hide {
            display: none;
            padding: 0px;
            transition: padding 0.4s;
        }
    }
`;

export const DetailsHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 6px;
    button {
        font-size: 0.9rem;
        padding: 4px 13px;
    }
    .title {
        margin: 10px 0px;
        margin-right: 15px;
    }
`;

export const CustomTableTr = styled(TableTr)`
    padding: 10px 17px;
    border-bottom: 0px;

    .btn-light {
        padding: 3px 12px;
        border-radius: 40px;
        border: 0px;
        background: ${(props) => props.theme.palette.backgroundLight};
    }

    th,
    td {
        border-bottom: 0px solid blue;
        text-align: left;
        line-height: 1.2;
        padding: 0px 4px;
        padding-left: 0px;
        padding-right: 10px;
        font-weight: 400;
        a {
            display: block;
            color: ${(props) => props.theme.palette.primary};
        }
    }
`;

export const BarcodeContainerView = styled.div`
    display: flex;
    flex-wrap: wrap;
    .code-card {
        margin-right: 10px;
        margin-bottom: 10px;
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .card-title {
            margin-bottom: 5px;
            font-size: 0.9rem;
            color: ${(props) => props.theme.palette.textLight};
        }

        canvas,
        img {
            padding: 10px;
            background: white;
            border-radius: 10px;
            height: 100px;
        }
    }
`;

export const BannerSliderContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    padding-top: 12px;
    padding-bottom: 0px;
    margin-right: 0px;
    align-items: center;
    ${breakpoint("lg")`
        margin-right: 20px;
        align-items: baseline;
        `}
    .swiper-container {
        border-radius: 11px;
        width: 100%;
        max-width: 500px;
    }
    .swiper-wrapper {
        width: 100%;
        height: auto;
        max-height: 480px;
    }
    ${breakpoint("md")`
    .swiper-wrapper {
        width: 400px;
        height: 400px;
    }
    `}
    ${breakpoint("xl")`
    .swiper-wrapper {
        width: 400px;
        height: 400px;
    }
    `}
    iframe {
        width: 100%;
        height: 100%;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .list-images {
        .swiper-slide {
            width: 100% !important;
            height: auto;
        }
    }

    .thumbs {
        margin-top: 15px;
        max-width: 300px;
        ${breakpoint("md")`
        max-width: auto;
        `}
        .swiper-container {
            border-radius: 0px;
        }
        .swiper-wrapper {
            height: auto;
        }
        .swiper-slide {
            border: 1px solid ${(props) => props.theme.palette.backgroundDark};
            height: 65px;
            width: 65px;
            border-radius: 8px;
            margin: 4px;
            overflow: hidden;
        }
        .swiper-slide-active {
            border: 1px solid ${(props) => props.theme.palette.primary};
        }
    }
`;

export const ProductSerialsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    .serial-line {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .index {
            margin-right: 10px;
            color: ${(props) => props.theme.palette.textLight};
        }
        .input-serial {
            width: 100%;
            border-radius: 40px;
        }
    }
`;
