import { IDeliverySummary } from "../store/types/deliveryOrder";
import { ProductPriceTypes } from "../store/types/product";

export const CHANGE_THEME = "appSettings/CHANGE_THEME";
export const SET_LANGUAGE = "appSettings/SET_LANGUAGE";
export const SET_LIST_PRODUCT_SHAPE = "appSettings/SET_LIST_PRODUCT_SHAPE";
export const SET_DEFAULT_PRICE = "appSettings/SET_DEFAULT_PRICE";
export const SET_DEFAULT_ORGANIZATION = "appSettings/SET_DEFAULT_ORGANIZATION";
export const SET_DEFAULT_WAREHOUSE = "appSettings/SET_DEFAULT_WAREHOUSE";
export const SET_DEFAULT_LOUNGE = "appSettings/SET_DEFAULT_LOUNGE";
export const SET_DELIVERY_SUMMARY = "appSettings/SET_DELIVERY_SUMMARY";
export const CHANGE_MENU_SMALL = "appSettings/CHANGE_MENU_SMALL";
export const SET_ALL_SETTINGS = "appSettings/SET_ALL_SETTINGS";

export const THEME_TITLE = "THEME";
export const THEME_LIGHT = "LIGHT";
export const THEME_DARK = "DARK";
export const APP_SETTINGS_KEY = "appSettings";

export type AppGripShape = "row" | "square" | undefined;
export type ThemeType = "LIGHT" | "DARK";

export interface IAppSettingsState {
    theme: ThemeType;
    language: string;
    productListShape: AppGripShape;
    orderListShape: AppGripShape;
    defaultPrice: ProductPriceTypes;
    defaultOrganizationId: string;
    menuSmall: boolean;
    defaultWarehouseId: string;
    deliverySummary: IDeliverySummary;
    defaultLoungeId: string;
    cashRegisterId: string;
    userUniqueIdentifier: string;
    appVersion: string;
}

export const initStateBase: IAppSettingsState = {
    theme: THEME_LIGHT,
    language: "es",
    productListShape: "square",
    orderListShape: "row",
    defaultPrice: "price",
    defaultOrganizationId: "",
    deliverySummary: { total: 0, delivered: 0, pending: 0 },
    menuSmall: false,
    defaultWarehouseId: "",
    defaultLoungeId: "",
    cashRegisterId: "",
    userUniqueIdentifier: "",
    appVersion: "",
};

export interface ChangeThemeType {
    type: typeof CHANGE_THEME;
}
export interface SetLanguageType {
    type: typeof SET_LANGUAGE;
    value: string;
}
export interface SetProductListShapeType {
    type: typeof SET_LIST_PRODUCT_SHAPE;
    shape: AppGripShape;
}

export interface SetDefaultPrice {
    type: typeof SET_DEFAULT_PRICE;
    value: ProductPriceTypes;
}
export interface SetDefaultOrganziation {
    type: typeof SET_DEFAULT_ORGANIZATION;
    value: string;
}
export interface SetDefaultWarehouse {
    type: typeof SET_DEFAULT_WAREHOUSE;
    value: string;
}
export interface SetDefaultLounge {
    type: typeof SET_DEFAULT_LOUNGE;
    value: string;
}
export interface SetDeliverySummary {
    type: typeof SET_DELIVERY_SUMMARY;
    value: IDeliverySummary;
}
export interface ChangeMenuSmall {
    type: typeof CHANGE_MENU_SMALL;
}

export interface SetAllSettings {
    type: typeof SET_ALL_SETTINGS;
    value: IAppSettingsState;
}

export type AppSettingsTypes =
    | ChangeThemeType
    | SetLanguageType
    | SetProductListShapeType
    | SetDefaultPrice
    | SetDefaultOrganziation
    | SetDefaultWarehouse
    | ChangeMenuSmall
    | SetDefaultLounge
    | SetDeliverySummary
    | SetAllSettings;
