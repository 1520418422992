import React from "react";
import { WahioLogoContainer } from "./styled";
import WahioLogo from "../../assets/images/wahiologo.png";

export default function WahioFooter() {
    return (
        <WahioLogoContainer className="grey">
            <a href="https://suite.wahio.com/" target="blank">
                <div className="image">
                    <img src={WahioLogo} alt="Wahio" />
                </div>
                <span className="name">Powered By Wahio</span>
            </a>
        </WahioLogoContainer>
    );
}
