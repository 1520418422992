import styled from "styled-components";
import { TableCustom } from "../_controls/tables/styled";
import { breakpoint } from "../../theme/themeProvider";

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const CloseContainer = styled.div`
    position: absolute;
    right: 8px;
    top: 9px;
    z-index: 1;
`;

export const SubContainer = styled.div`
    position: relative;
    display: flex;
    .display-block {
        display: block;
    }
    .diplay-none: {
        display: none;
    }
`;
interface IMobileProps {
    isMobile: boolean;
}
export interface IHideProps extends IMobileProps {
    hide: boolean;
}
export const ProductListContainer = styled.div`
    ${(props: IHideProps) => (props.isMobile ? "" : " max-width: 333px;")}
    width: 100%;
    border-right: 1px solid transparent;

    border-top-left-radius: 10px;
    overflow: hidden;
    min-width: 310px;

    ${(props: IHideProps) => (props.hide ? "display: none;" : "")}
    ${breakpoint("xl")`
    max-width: 400px;
    `}
`;

export const OrderContainerParent = styled.div`
    box-sizing: border-box;
    padding: 15px 0px;
    ${breakpoint("sm")`
    padding: 20px;
    `}
    width: 100%;
`;

export const OrderContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${(props: IHideProps) => (props.hide ? "display: none;" : "")}
    width: 100%;
    border-radius: 0px;
    ${breakpoint("md")`
        border-radius: 8px;
    `}
    position: relative;

    box-sizing: border-box;
    height: auto;
    width: 100%;
    max-height: calc(100vh - 100px);
`;

export const FlexContainer = styled.div`
    display: flex;
`;

export const TopView = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    flex-wrap: wrap;

    .margin,
    .dropdown {
        margin-right: 10px;
        margin-bottom: 10px;
    }

    button {
        border-radius: 50px;
    }
    padding-left: 15px;
    padding-top: 15px;
    ${breakpoint("md")`
   padding: 0px;
`}
`;

export const TopLabelContainer = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    grid-gap: 10px;
    flex-wrap: wrap;
`;

export const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0rem 0.8rem;
    .label-title {
        font-size: 0.9rem;
        color: ${(props) => props.theme.palette.textLight};
    }
`;

export const TableContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
`;

export const CardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
`;

export const SummaryContainer = styled.div`
    display: flex;
    width: 100%;
`;

export const ContainerLoading = styled.div`
    margin: 3rem;
    text-align: center;
`;

export const DetailsContainer = styled.div`
    ${(props: IHideProps) => (props.hide ? "display: none;" : "display: flex;")}
    padding: 0rem;
    flex-direction: column;
    padding-bottom: 15px;
    padding-top: 5px;
    flex-wrap: wrap;
    flex-direction: column;
    ${breakpoint("md")`
        flex-direction: row;
    `}
`;

const CardMarginDesign = styled.div`
    &.margin {
        padding: 15px 15px;
        background: ${(props) => props.theme.palette.backgroundLight};
        border-radius: 10px;
        margin: 6px 10px;

        .btn-address {
            background-color: transparent;
            border-color: ${(props) => props.theme.palette.text}30;
            &:hover {
                background-color: ${(props) => props.theme.palette.background};
            }
        }
        .select__control {
            background-color: transparent;
            border-color: ${(props) => props.theme.palette.text}30;

            &.select__control--menu-is-open {
                border-color: ${(props) => props.theme.palette.backgroundDark};
                border-color: ${(props) => props.theme.palette.primary};
                background-color: ${(props) => props.theme.palette.background};
            }
            &:hover {
                background-color: ${(props) => props.theme.palette.background};
            }
        }

        .textbox-numeral {
            background-color: transparent;
            border-color: ${(props) => props.theme.palette.text}30;
            &:focus {
                background-color: ${(props) => props.theme.palette.background};
                border-color: ${(props) => props.theme.palette.primary};
            }
            &:hover {
                background-color: ${(props) => props.theme.palette.background};
            }
        }
    }
    .label {
        font-size: 0.9rem;
        color: ${(props) => props.theme.palette.textLight};
        margin-bottom: 5px;
    }
`;

export const DetailCard = styled(CardMarginDesign)`
    display: flex;
    flex-direction: column;

    .width-limit {
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
    }
    .textbox-numeral {
        padding: 6px;
        border-radius: 20px;
    }
    .card-input { 
        border-radius: 20px;
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        background: ${(props) => props.theme.palette.background};
    }
`;

export const SelectBody = styled(CardMarginDesign)`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .select__menu {
        top: 100%;
        border-radius: 8px;
        box-shadow: 1px 2px 9px ${(props) => props.theme.palette.shadowDark};
        border: 1px solid ${(props) => props.theme.palette.backgroundDark};
        overflow: hidden;
    }
    .select__indicator {
        padding: 4px 8px;
    }
    .select__control {
        border-radius: 30px;
        background-color: ${(props) => props.theme.palette.background};
        border-color: ${(props) => props.theme.palette.backgroundDark};
        color: ${(props) => props.theme.palette.text};
        min-height: 32px;
        &.select__control--menu-is-open {
            border-color: ${(props) => props.theme.palette.backgroundDark};
        }
    }
    .select__single-value {
        color: ${(props) => props.theme.palette.text};
    }
    .select__input-container {
        color: ${(props) => props.theme.palette.textLight};
    }
    .select__option--is-focused {
        background-color: ${(props) => props.theme.palette.primary}40;
        color: ${(props) => props.theme.palette.text};
        &.select__option--is-selected {
            background-color: ${(props) => props.theme.palette.primaryDark};
        }
    }
    .select__option--is-selected {
        background-color: ${(props) => props.theme.palette.primary};
        color: white;
    }
    .select__menu-list {
        background: ${(props) => props.theme.palette.background};
        padding: 0px;
    }
    .select__indicator-separator {
        background: ${(props) => props.theme.palette.backgroundDark};
    }

    .react-select-basic {
        min-width: 200px;
    }
`;

export const DetailsBody = styled.div`
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    margin: 6px 0px;

    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundDark};
    padding: 3px 1rem;
    padding-bottom: 16px;
    max-width: fit-content;
    ${breakpoint("md")`
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 8px;
    padding: 0.5rem 1rem;
    margin: 6px;
`}

    justify-content: center;
    p {
        margin: 0px;
    }
    .light {
        color: ${(props) => props.theme.palette.textLight};
        margin-right: 10px;
    }
    .content {
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 10px;
    }
`;

export const RowContainer = styled.div`
    display: flex;
    align-items: center;
    .content-col {
        display: flex;
        flex-direction: column;
        max-width: 200px;
        overflow: hidden;
        padding-right: 10px;

        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
        }
    }

    .small {
        width: 25px;
        height: 25px;
        min-width: 25px;
    }
`;

export const DeliveryOptionContainer = styled(RowContainer)`
    grid-gap: 20px;
    flex-wrap: wrap;
    .separator {
        display: none;
        width: 1px;
        height: 25px;
        background: ${(props) => props.theme.palette.backgroundDark};
    }
    flex-direction: column;
    align-items: flex-start;
    ${breakpoint("md")`
        .separator { 
            display: block;
        }
        align-items: center;
        flex-direction: row;
    `}
`;

export const FlexColDetailContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CostContainer = styled.div`
    input {
        border-radius: 40px;
        padding: 2px 9px;
        margin-top: 2px;
        font-weight: 500;
    }
`;

export const EmptyItemsMessage = styled.div`
    padding-left: 2rem;
    padding-bottom: 1rem;
    color: ${(props) => props.theme.palette.textLight};
`;

export const DraftContainer = styled.div`
    padding: 1rem;
    .number {
        color: ${(props) => props.theme.palette.primary};
    }
`;

export const DraftSubcontainer = styled.div`
    background: ${(props) => props.theme.palette.backgroundLight};
    border: 0px solid ${(props) => props.theme.palette.backgroundDark};
    padding: 1rem;

    border-radius: 7px;
`;

export const TableCustomCustomer = styled(TableCustom)`
    min-width: 690px;
`;

export const OrderHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 27px;
    height: 45px;
    border-bottom: 1px solid ${(props) => props.theme.palette.backgroundLight};
    overflow: auto;
    .btn-back {
        background: ${(props) => props.theme.palette.background};
    }
    .header-title {
        font-size: 1.1rem;
        font-weight: 500;
        white-space: nowrap;
    }
    .btn-new {
        border-radius: 50px;
        padding: 3px 14px;
        color: ${(props) => props.theme.palette.textLight};
        background: ${(props) => props.theme.palette.backgroundDark};
        font-size: 0.9rem;
        font-weight: 500;
    }
`;

export const OrderStyles = styled.div`
    .btn-container {
        button {
            min-width: 25px;
            width: 25px;
            height: 25px;
        }
    }
    .dropbtn {
        border-radius: 50px;
    }
    .light {
        font-size: 0.95rem;
        color: ${(props) => props.theme.palette.textLight};
    }
    .pright {
        padding-left: 10px !important;
        padding-right: 15px !important;
    }
    .style-neutral {
        background: ${(props) => props.theme.palette.backgroundLight};
        color: ${(props) => props.theme.palette.textLight};
        border-color: transparent;
    }
    .style-orange {
        background: #ff99002d;
        color: #ff9800 !important;
        border-color: transparent;
    }
    .style-blue {
        background: #2962ff2c;
        color: #2962ff;
        border-color: transparent;
    }
    .style-red {
        background: #f443362d;
        color: #f44336;
        border-color: transparent;
    }
    .style-green {
        background: #00968838;
        color: #009688;
        border-color: transparent;
    }
    .res-active {
        background: transparent;
        color: ${(props) => props.theme.palette.textLight};
        border-color: transparent;
    }

    .decorator {
        border-radius: 22px;
        color: ${(props) => props.theme.palette.textLight};
        display: inline-flex;
        padding: 1px 11px;
        align-items: center;
        font-size: 0.9rem;
        grid-gap: 6px;
        .icon {
            margin-right: 6px;
        }
        &.active {
            background: ${(props) => props.theme.palette.backgroundLight};
            span {
                color: #2962ff;
            }
        }
        span {
            font-size: 0.8rem;
        }
    }
    .btn-status {
        padding: 2px 10px;
        border-radius: 30px;
        margin-right: 10px;
    }
`;
