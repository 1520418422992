import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 14px;
  }
  body, button, input , textarea {
    font-family: Roboto, 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI',
    -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  }
  body {
    font-size: 15px;
   
  }
 html, body { 
  background: ${(props) => props.theme.palette.background};
  color: ${(props) => props.theme.palette.text};
  margin: 0;
  padding: 0; 
 }
  #app {
    min-height: 100%;
    min-width: 100%;
    height: 100%;
    width: 100%;
  }
  p,
  label {
    line-height: 1.2em;
  }
  a {
    color:  ${(props) => props.theme.palette.primary};
    text-decoration: none;
    &:visited {
      color: ${(props) => props.theme.palette.primary};
    }
  }
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

hr {
  height: 1px;
  background: ${(props) => props.theme.palette.backgroundDark};
  width: 100%;
  border: 0px;
}
.large-font-2 { 
  font-size: 2rem;
}
.large-font-3 { 
  font-size: 3rem;
}
.w-100 {
  width: 100%;
}
.m-0 {
  margin: 0px;
}
.ml-1 {
  margin-left: 10px;
}
.mb-1 { 
  margin-bottom: 10px;
}
.mr-1 {
  margin-right: 10px;
}
.mt-1 { 
  margin-top: 10px;
}
.mt-2 { 
  margin-top: 20px;
}
ul li { 
  list-style: none;
}
.m-tb {
  margin-bottom: 3px;
  margin-top: 3px;
}
.pt-1 {
  padding-top: 10px;
}
.pb-0 
 { 
   padding-bottom: 0px;
 }
/* Track */
::-webkit-scrollbar-track {
    background: var(--bg-primary-2);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #84848457;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.palette.backgroundDark};
}
.text-center {
  text-align: center;
}



`;

export default GlobalStyle;
