import React from "react";
import styled from "styled-components";

interface IItemPair {
    title: any;
    value: any;
    className?: string;
}

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    .title {
        font-size: 0.9rem;
        color: ${(props) => props.theme.palette.textLight};
    }
    .value {
        font-weight: 500px;
    }
`;

const LabelPair = (props: IItemPair) => {
    return (
        <LabelContainer className='labelpair'>
            <span className="title">{props.title}</span>
            <span className="value">{props.value}</span>
        </LabelContainer>
    );
};

export default LabelPair;
