import { defineMessages } from "react-intl";

const base = "components.productProfile";

export default defineMessages({
    productsList: {
        id: `${base}.productsList`,
        defaultMessage: "Productos",
    },
    edit: {
        id: `${base}.edit`,
        defaultMessage: "Editar",
    },
    details: {
        id: `${base}.details`,
        defaultMessage: "Detalles",
    },
    basePrice: {
        id: `${base}.basePrice`,
        defaultMessage: "Precio base",
    },
    salePrice: {
        id: `${base}.salePrice`,
        defaultMessage: "Precio",
    },
    description: {
        id: `${base}.description`,
        defaultMessage: "Descripción",
    },
    brand: {
        id: `${base}.brand`,
        defaultMessage: "Marca",
    },
    manufacturer: {
        id: `${base}.manufacturer`,
        defaultMessage: "Manufactura",
    },
    imageList: {
        id: `${base}.imageList`,
        defaultMessage: "Imágenes del producto",
    },
    productCosts: {
        id: `${base}.productCosts`,
        defaultMessage: "Costos del Producto",
    },
    cost: {
        id: `${base}.cost`,
        defaultMessage: "Costo:",
    },
    averageCost: {
        id: `${base}.averageCost`,
        defaultMessage: "Costo promedio:",
    },
    weightedCost: {
        id: `${base}.weightedCost`,
        defaultMessage: "Costo ponderado:",
    },
    lastCost: {
        id: `${base}.lastCost`,
        defaultMessage: "Último costo:",
    },
    lastPurchaseDate: {
        id: `${base}.lastPurchaseDate`,
        defaultMessage: "Fecha última compra:",
    },
    productComposition: {
        id: `${base}.productComposition`,
        defaultMessage: "Composición del producto",
    },
     barcodesAndSkus: {
        id: `${base}.barcodesAndSkus`,
        defaultMessage: "Códigos y Skus",
    },
     barcode: {
        id: `${base}.barcode`,
        defaultMessage: "Código de barras:",
    },
     sku: {
        id: `${base}.sku`,
        defaultMessage: "Sku:",
    },
     salesOf: {
        id: `${base}.salesOf`,
        defaultMessage: "Ventas de",
    },
     settingDoneSuccessfully: {
        id: `${base}.settingDoneSuccessfully`,
        defaultMessage: "Ajuste realizado con éxito",
    },
     confirm: {
        id: `${base}.confirm`,
        defaultMessage: "Confirmar",
    },
     thisValueIsIncludedInThePrice: {
        id: `${base}.thisValueIsIncludedInThePrice`,
        defaultMessage: "Este valor está incluido en el precio",
    },
     example: {
        id: `${base}.example`,
        defaultMessage: "Ejemplo:",
    },
    mainPrice: {
        id: `${base}.mainPrice`,
        defaultMessage: "precio principal",
    },
    priceWithout: {
        id: `${base}.priceWithout`,
        defaultMessage: "Precio sin",
    },
    reference: {
        id: `${base}.reference`,
        defaultMessage: "Referencia:",
    },
    stockControl: {
        id: `${base}.stockControl`,
        defaultMessage: "Control del stock:",
    },
    category: {
        id: `${base}.category`,
        defaultMessage: "Categoría:",
    },
    yes: {
        id: `${base}.yes`,
        defaultMessage: "Si",
    },
    no: {
        id: `${base}.no`,
        defaultMessage: "No",
    },
    taxes: {
        id: `${base}.taxes`,
        defaultMessage: "Impuestos:",
    },
    created: {
        id: `${base}.created`,
        defaultMessage: "Creado:",
    },
    updated: {
        id: `${base}.updated`,
        defaultMessage: "Actualizado:",
    },
    theInventoryOfThisProductDependsOnItsComponent: {
        id: `${base}.theInventoryOfThisProductDependsOnItsComponent`,
        defaultMessage: "El inventario de este producto depende de sus componente",
    },
    inventoryOf: {
        id: `${base}.inventoryOf`,
        defaultMessage: "Inventario de",
    },
    reserved: {
        id: `${base}.reserved`,
        defaultMessage: "Reservado",
    },
    
});
