import styled from "styled-components";

export const WahioLogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    a {
        display: flex;
        align-items: center;
        margin-top: 15px;
    }
    .name {
        color: ${(props) => props.theme.palette.text};
    }
    .image {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &.grey {
        filter: grayscale(100%);
        opacity: 0.6;
        .image {
            width: 20px;
            height: 20px;
        }
        .name {
            font-weight: 400;
           
        }
    }
`;
