import React from "react";
import { Link } from "react-router-dom";

import WahioLogo from "../../assets/images/wahiologo.png";
import PATHS from "../../constants/paths";
import { WahioLogoContainer } from "./styled";

export default function WahioHeader() {
    return (
        <WahioLogoContainer>
            <Link to={PATHS.home}>
                <div className="image">
                    <img src={WahioLogo} alt="Wahio" />
                </div>
                <span className="name">Wahio Product</span>
            </Link>
        </WahioLogoContainer>
    );
}
