import styled from "styled-components";
import { IThemePalette } from "../../theme/themePalette";

interface ContainerProps {
    withBorder?: boolean;
    menuSmall?: boolean;
    padding?: number;
    theme: {
        palette: IThemePalette;
    };
}

export const Container = styled.div`
    padding: ${(props) => props.padding + "px"};
    margin: auto;
    position: relative;

    ${(props: ContainerProps) => (props.withBorder ? `order: 1px solid ${props.theme.palette.backgroundDark};` : "")}

    &.size-sm {
        max-width: 600px;
    }
    &.size-md {
        max-width: 900px;
    }
    &.size-lg {
        max-width: 1280px;
    }
`;

export const SubContainer = styled.div`
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    border-radius: 8px;
    margin-bottom: 15px;
    padding: 15px;
`;
