import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { IProductProfile } from ".";
import { VariantOptionValue } from "../../store/types/product";
import { IProductTax } from "../../store/types/tax";
import { formatMoney, getDateFormatLLLL } from "../../utils";
import LabelPair from "../_controls/LabelPair";
import messages from "./messages";
import { DetailsHeader, FlexContainerWrap, UlCustom } from "./styled";
import Select from "react-select";
import { SelectBody } from "../OrderStyles/styled";
import { SelectBaseOption } from "../../store/types";

interface IDetailsProps extends IProductProfile {
    fetchCompleted: boolean;
    setOpenEdit: (value: boolean) => void;
}
interface IPriceItem {
    key: number;
    value: number;
}

interface ITaxItem {
    item: IProductTax;
    productPrice: number;
}

export default function ProductDetails(props: IDetailsProps) {
    const { product } = props;
    const { customCategory } = product;
    const intl = useIntl();
    const [variantOptionValues, setVariantOptionValues] = useState<VariantOptionValue[]>([]);

    const categoryName = customCategory ? customCategory.name : "---";

    useEffect(() => {
        if (product.includesVariants && product.variants && product.variants.length > 0 && !props.productVariant) {
            let variant = product.variants[0];
            let options = variant.options;
            setVariantOptionValues(options);
            props.setProductVariant(variant);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSelectOption = (item: VariantOptionValue) => {
        let option: SelectBaseOption = {
            id: item.key,
            value: item.value,
            label: item.value,
        };
        return option;
    };

    const getDefaultOption = (optionName: string) => {
        let option = variantOptionValues.find((x) => x.variantName === optionName);
        return option ? getSelectOption(option) : undefined;
    };

    const onChangeVariantOption = (option: VariantOptionValue) => {
        let newOptinValues: VariantOptionValue[] = [];
        let exists = false;
        variantOptionValues.forEach((element) => {
            if (element.variantName === option.variantName) {
                newOptinValues.push(option);
                exists = true;
            } else {
                newOptinValues.push(element);
            }
        });

        if (!exists) {
            newOptinValues.push(option);
        }

        let valuesLine = newOptinValues.map((item) => item.value).join("-");
        let existingVariant = product.variants.find((x) => x.valuesLine === valuesLine);

        if (existingVariant) {
            props.setProductVariant(existingVariant);
        }

        setVariantOptionValues(newOptinValues);
    };
    const onRemoveVariantOption = (option: string) => {
        setVariantOptionValues(variantOptionValues.filter((x) => x.variantName !== option));
    };

    return (
        <div className="w-100">
            <DetailsHeader>
                <h2 className="title">{product.name}</h2>
            </DetailsHeader>
            <div>
                <UlCustom>
                    {product.code && (
                        <li>
                            <span className="title">{intl.formatMessage(messages.barcode)}</span> <span>{product.code}</span>
                        </li>
                    )}
                    {product.sku && (
                        <li>
                            <span className="title">{intl.formatMessage(messages.sku)}</span> <span>{product.sku}</span>
                        </li>
                    )}
                    {product.reference && (
                        <li>
                            <span className="title">{intl.formatMessage(messages.reference)}</span> <span>{product.reference}</span>
                        </li>
                    )}

                    <li className="mt-1">
                        <span className="title">{intl.formatMessage(messages.category)}</span>

                        <div className="columns">
                            <span>{categoryName}</span>
                            {product.productCategories &&
                                product.productCategories.map((item, index) => <span key={index}>{item.customCategory.name}</span>)}
                        </div>
                    </li>
                </UlCustom>
                <div className="dates">
                    {product.dateUpdated > product.dateCreated && (
                        <span>
                            {intl.formatMessage(messages.updated)} {getDateFormatLLLL(product.dateUpdated)}
                        </span>
                    )}
                </div>

                <h1>{formatMoney(product.price)}</h1>

                {(product.brand || product.manufacturer) && (
                    <FlexContainerWrap>
                        <hr />
                        {product.brand && (
                            <LabelPair className="mr-3" title={intl.formatMessage(messages.brand)} value={product.brand?.name} />
                        )}
                        {product.manufacturer && (
                            <LabelPair
                                className="mr-3"
                                title={intl.formatMessage(messages.manufacturer)}
                                value={product.manufacturer?.name}
                            />
                        )}
                    </FlexContainerWrap>
                )}

                {product.variantOptions && product.variantOptions.length > 0 && (
                    <div>
                        <h3>Variantes</h3>
                        {product.variantOptions.map((variantOption) => (
                            <SelectBody className="mt-1">
                                <span className="label">{variantOption.name}</span>
                                <Select
                                    placeholder="Seleccionar"
                                    className="select-min-len"
                                    classNamePrefix="select"
                                    key={props.productVariant?.id}
                                    defaultValue={getDefaultOption(variantOption.name)}
                                    isDisabled={false}
                                    onChange={(baseOption) =>
                                        baseOption
                                            ? onChangeVariantOption({
                                                  value: baseOption.value,
                                                  index: 0,
                                                  variantName: variantOption.name,
                                                  key: `${variantOption.name}-${baseOption.value}`,
                                              })
                                            : onRemoveVariantOption(variantOption.name)
                                    }
                                    isClearable={true}
                                    isRtl={false}
                                    isSearchable={true}
                                    name="seller"
                                    options={variantOption.values.map((value, index) =>
                                        getSelectOption({
                                            value,
                                            index,
                                            variantName: variantOption.name,
                                            key: `${variantOption.name}-${value}`,
                                        })
                                    )}
                                />
                            </SelectBody>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
