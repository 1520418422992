import { ColombianDianGeographic } from "../store/types/geographic";
export const horMenuHeight = "55px";
export const horMenuHeightx2 = "111px";
export const menuWidthMinus = "-220px";
export const menuWidth = "220px";
export const smallMenu = "80px";

export const geographicData: ColombianDianGeographic[] = [
    { departmentCode: "05", department: "Antioquia", municipalityCode: "001", municipality: "Medellín" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "002", municipality: "Abejorral" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "004", municipality: "Abriaquí" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "021", municipality: "Alejandría" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "030", municipality: "Amagá" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "031", municipality: "Amalfi" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "034", municipality: "Andes" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "036", municipality: "Angelópolis" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "038", municipality: "Angostura" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "040", municipality: "Anorí" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "832", municipality: "Tununguá" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "044", municipality: "Anza" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "045", municipality: "Apartadó" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "051", municipality: "Arboletes" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "055", municipality: "Argelia" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "059", municipality: "Armenia" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "079", municipality: "Barbosa" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "088", municipality: "Bello" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "091", municipality: "Betania" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "093", municipality: "Betulia" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "101", municipality: "Ciudad Bolívar" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "107", municipality: "Briceño" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "113", municipality: "Buriticá" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "120", municipality: "Cáceres" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "125", municipality: "Caicedo" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "129", municipality: "Caldas" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "134", municipality: "Campamento" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "138", municipality: "Cañasgordas" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "142", municipality: "Caracolí" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "145", municipality: "Caramanta" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "147", municipality: "Carepa" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "476", municipality: "Motavita" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "150", municipality: "Carolina" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "154", municipality: "Caucasia" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "172", municipality: "Chigorodó" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "190", municipality: "Cisneros" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "197", municipality: "Cocorná" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "206", municipality: "Concepción" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "209", municipality: "Concordia" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "212", municipality: "Copacabana" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "234", municipality: "Dabeiba" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "237", municipality: "Don Matías" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "240", municipality: "Ebéjico" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "250", municipality: "El Bagre" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "264", municipality: "Entrerrios" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "266", municipality: "Envigado" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "282", municipality: "Fredonia" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "675", municipality: "San Bernardo del Viento" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "306", municipality: "Giraldo" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "308", municipality: "Girardota" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "310", municipality: "Gómez Plata" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "361", municipality: "Istmina" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "315", municipality: "Guadalupe" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "318", municipality: "Guarne" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "321", municipality: "Guatapé" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "347", municipality: "Heliconia" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "353", municipality: "Hispania" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "360", municipality: "Itagui" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "361", municipality: "Ituango" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "086", municipality: "Belmira" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "368", municipality: "Jericó" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "376", municipality: "La Ceja" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "380", municipality: "La Estrella" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "390", municipality: "La Pintada" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "400", municipality: "La Unión" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "411", municipality: "Liborina" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "425", municipality: "Maceo" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "440", municipality: "Marinilla" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "467", municipality: "Montebello" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "475", municipality: "Murindó" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "480", municipality: "Mutatá" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "483", municipality: "Nariño" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "490", municipality: "Necoclí" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "495", municipality: "Nechí" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "501", municipality: "Olaya" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "541", municipality: "Peñol" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "543", municipality: "Peque" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "576", municipality: "Pueblorrico" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "579", municipality: "Puerto Berrío" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "585", municipality: "Puerto Nare" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "591", municipality: "Puerto Triunfo" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "604", municipality: "Remedios" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "607", municipality: "Retiro" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "615", municipality: "Rionegro" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "628", municipality: "Sabanalarga" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "631", municipality: "Sabaneta" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "642", municipality: "Salgar" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "189", municipality: "Ciénega" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "699", municipality: "Santacruz" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "652", municipality: "San Francisco" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "656", municipality: "San Jerónimo" },
    { departmentCode: "68", department: "Santander", municipalityCode: "575", municipality: "Puerto Wilches" },
    { departmentCode: "68", department: "Santander", municipalityCode: "573", municipality: "Puerto Parra" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "660", municipality: "San Luis" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "664", municipality: "San Pedro" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "667", municipality: "San Rafael" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "670", municipality: "San Roque" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "674", municipality: "San Vicente" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "679", municipality: "Santa Bárbara" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "690", municipality: "Santo Domingo" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "697", municipality: "El Santuario" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "736", municipality: "Segovia" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "761", municipality: "Sopetrán" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "789", municipality: "Támesis" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "790", municipality: "Tarazá" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "792", municipality: "Tarso" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "809", municipality: "Titiribí" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "819", municipality: "Toledo" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "837", municipality: "Turbo" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "842", municipality: "Uramita" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "847", municipality: "Urrao" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "854", municipality: "Valdivia" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "856", municipality: "Valparaíso" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "858", municipality: "Vegachí" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "861", municipality: "Venecia" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "885", municipality: "Yalí" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "887", municipality: "Yarumal" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "890", municipality: "Yolombó" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "893", municipality: "Yondó" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "895", municipality: "Zaragoza" },
    { departmentCode: "08", department: "Atlántico", municipalityCode: "001", municipality: "Barranquilla" },
    { departmentCode: "08", department: "Atlántico", municipalityCode: "078", municipality: "Baranoa" },
    { departmentCode: "08", department: "Atlántico", municipalityCode: "141", municipality: "Candelaria" },
    { departmentCode: "08", department: "Atlántico", municipalityCode: "296", municipality: "Galapa" },
    { departmentCode: "08", department: "Atlántico", municipalityCode: "421", municipality: "Luruaco" },
    { departmentCode: "08", department: "Atlántico", municipalityCode: "433", municipality: "Malambo" },
    { departmentCode: "08", department: "Atlántico", municipalityCode: "436", municipality: "Manatí" },
    { departmentCode: "08", department: "Atlántico", municipalityCode: "549", municipality: "Piojó" },
    { departmentCode: "08", department: "Atlántico", municipalityCode: "558", municipality: "Polonuevo" },
    { departmentCode: "08", department: "Atlántico", municipalityCode: "634", municipality: "Sabanagrande" },
    { departmentCode: "08", department: "Atlántico", municipalityCode: "638", municipality: "Sabanalarga" },
    { departmentCode: "08", department: "Atlántico", municipalityCode: "675", municipality: "Santa Lucía" },
    { departmentCode: "08", department: "Atlántico", municipalityCode: "685", municipality: "Santo Tomás" },
    { departmentCode: "08", department: "Atlántico", municipalityCode: "758", municipality: "Soledad" },
    { departmentCode: "08", department: "Atlántico", municipalityCode: "770", municipality: "Suan" },
    { departmentCode: "08", department: "Atlántico", municipalityCode: "832", municipality: "Tubará" },
    { departmentCode: "08", department: "Atlántico", municipalityCode: "849", municipality: "Usiacurí" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "006", municipality: "Achí" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "042", municipality: "Arenal" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "052", municipality: "Arjona" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "062", municipality: "Arroyohondo" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "140", municipality: "Calamar" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "160", municipality: "Cantagallo" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "188", municipality: "Cicuco" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "212", municipality: "Córdoba" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "222", municipality: "Clemencia" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "248", municipality: "El Guamo" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "430", municipality: "Magangué" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "433", municipality: "Mahates" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "440", municipality: "Margarita" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "458", municipality: "Montecristo" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "468", municipality: "Mompós" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "473", municipality: "Morales" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "490", municipality: "Norosí" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "549", municipality: "Pinillos" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "580", municipality: "Regidor" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "600", municipality: "Río Viejo" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "647", municipality: "San Estanislao" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "650", municipality: "San Fernando" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "657", municipality: "San Juan Nepomuceno" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "673", municipality: "Santa Catalina" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "683", municipality: "Santa Rosa" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "744", municipality: "Simití" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "760", municipality: "Soplaviento" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "780", municipality: "Talaigua Nuevo" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "810", municipality: "Tiquisio" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "836", municipality: "Turbaco" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "838", municipality: "Turbaná" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "873", municipality: "Villanueva" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "001", municipality: "Tunja" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "022", municipality: "Almeida" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "047", municipality: "Aquitania" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "051", municipality: "Arcabuco" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "090", municipality: "Berbeo" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "092", municipality: "Betéitiva" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "097", municipality: "Boavita" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "104", municipality: "Boyacá" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "106", municipality: "Briceño" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "109", municipality: "Buena Vista" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "114", municipality: "Busbanzá" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "131", municipality: "Caldas" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "135", municipality: "Campohermoso" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "162", municipality: "Cerinza" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "172", municipality: "Chinavita" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "176", municipality: "Chiquinquirá" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "180", municipality: "Chiscas" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "183", municipality: "Chita" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "185", municipality: "Chitaraque" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "187", municipality: "Chivatá" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "204", municipality: "Cómbita" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "212", municipality: "Coper" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "215", municipality: "Corrales" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "218", municipality: "Covarachía" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "223", municipality: "Cubará" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "224", municipality: "Cucaita" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "226", municipality: "Cuítiva" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "232", municipality: "Chíquiza" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "236", municipality: "Chivor" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "238", municipality: "Duitama" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "244", municipality: "El Cocuy" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "248", municipality: "El Espino" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "272", municipality: "Firavitoba" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "276", municipality: "Floresta" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "293", municipality: "Gachantivá" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "296", municipality: "Gameza" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "299", municipality: "Garagoa" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "317", municipality: "Guacamayas" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "322", municipality: "Guateque" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "325", municipality: "Guayatá" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "332", municipality: "Güicán" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "362", municipality: "Iza" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "367", municipality: "Jenesano" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "368", municipality: "Jericó" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "377", municipality: "Labranzagrande" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "380", municipality: "La Capilla" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "401", municipality: "La Victoria" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "425", municipality: "Macanal" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "442", municipality: "Maripí" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "455", municipality: "Miraflores" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "464", municipality: "Mongua" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "466", municipality: "Monguí" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "469", municipality: "Moniquirá" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "480", municipality: "Muzo" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "491", municipality: "Nobsa" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "494", municipality: "Nuevo Colón" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "500", municipality: "Oicatá" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "507", municipality: "Otanche" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "511", municipality: "Pachavita" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "514", municipality: "Páez" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "516", municipality: "Paipa" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "518", municipality: "Pajarito" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "522", municipality: "Panqueba" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "531", municipality: "Pauna" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "533", municipality: "Paya" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "542", municipality: "Pesca" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "550", municipality: "Pisba" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "572", municipality: "Puerto Boyacá" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "580", municipality: "Quípama" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "599", municipality: "Ramiriquí" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "600", municipality: "Ráquira" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "621", municipality: "Rondón" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "632", municipality: "Saboyá" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "638", municipality: "Sáchica" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "646", municipality: "Samacá" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "660", municipality: "San Eduardo" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "673", municipality: "San Mateo" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "686", municipality: "Santana" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "690", municipality: "Santa María" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "696", municipality: "Santa Sofía" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "720", municipality: "Sativanorte" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "723", municipality: "Sativasur" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "740", municipality: "Siachoque" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "753", municipality: "Soatá" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "755", municipality: "Socotá" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "757", municipality: "Socha" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "759", municipality: "Sogamoso" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "761", municipality: "Somondoco" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "762", municipality: "Sora" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "763", municipality: "Sotaquirá" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "764", municipality: "Soracá" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "774", municipality: "Susacón" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "776", municipality: "Sutamarchán" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "778", municipality: "Sutatenza" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "790", municipality: "Tasco" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "798", municipality: "Tenza" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "804", municipality: "Tibaná" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "808", municipality: "Tinjacá" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "810", municipality: "Tipacoque" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "814", municipality: "Toca" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "820", municipality: "Tópaga" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "822", municipality: "Tota" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "835", municipality: "Turmequé" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "839", municipality: "Tutazá" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "842", municipality: "Umbita" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "861", municipality: "Ventaquemada" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "879", municipality: "Viracachá" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "897", municipality: "Zetaquira" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "001", municipality: "Manizales" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "013", municipality: "Aguadas" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "042", municipality: "Anserma" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "050", municipality: "Aranzazu" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "088", municipality: "Belalcázar" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "174", municipality: "Chinchiná" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "272", municipality: "Filadelfia" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "380", municipality: "La Dorada" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "388", municipality: "La Merced" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "433", municipality: "Manzanares" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "442", municipality: "Marmato" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "446", municipality: "Marulanda" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "486", municipality: "Neira" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "495", municipality: "Norcasia" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "513", municipality: "Pácora" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "524", municipality: "Palestina" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "541", municipality: "Pensilvania" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "614", municipality: "Riosucio" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "616", municipality: "Risaralda" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "653", municipality: "Salamina" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "662", municipality: "Samaná" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "665", municipality: "San José" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "777", municipality: "Supía" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "867", municipality: "Victoria" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "873", municipality: "Villamaría" },
    { departmentCode: "17", department: "Caldas", municipalityCode: "877", municipality: "Viterbo" },
    { departmentCode: "18", department: "Caquetá", municipalityCode: "001", municipality: "Florencia" },
    { departmentCode: "18", department: "Caquetá", municipalityCode: "029", municipality: "Albania" },
    { departmentCode: "18", department: "Caquetá", municipalityCode: "205", municipality: "Curillo" },
    { departmentCode: "18", department: "Caquetá", municipalityCode: "247", municipality: "El Doncello" },
    { departmentCode: "18", department: "Caquetá", municipalityCode: "256", municipality: "El Paujil" },
    { departmentCode: "18", department: "Caquetá", municipalityCode: "479", municipality: "Morelia" },
    { departmentCode: "18", department: "Caquetá", municipalityCode: "592", municipality: "Puerto Rico" },
    { departmentCode: "18", department: "Caquetá", municipalityCode: "756", municipality: "Solano" },
    { departmentCode: "18", department: "Caquetá", municipalityCode: "785", municipality: "Solita" },
    { departmentCode: "18", department: "Caquetá", municipalityCode: "860", municipality: "Valparaíso" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "001", municipality: "Popayán" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "022", municipality: "Almaguer" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "050", municipality: "Argelia" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "075", municipality: "Balboa" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "100", municipality: "Bolívar" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "110", municipality: "Buenos Aires" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "130", municipality: "Cajibío" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "137", municipality: "Caldono" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "142", municipality: "Caloto" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "212", municipality: "Corinto" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "256", municipality: "El Tambo" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "290", municipality: "Florencia" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "300", municipality: "Guachené" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "318", municipality: "Guapi" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "355", municipality: "Inzá" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "364", municipality: "Jambaló" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "392", municipality: "La Sierra" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "397", municipality: "La Vega" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "418", municipality: "López" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "450", municipality: "Mercaderes" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "455", municipality: "Miranda" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "473", municipality: "Morales" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "513", municipality: "Padilla" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "532", municipality: "Patía" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "533", municipality: "Piamonte" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "548", municipality: "Piendamó" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "573", municipality: "Puerto Tejada" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "585", municipality: "Puracé" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "622", municipality: "Rosas" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "701", municipality: "Santa Rosa" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "743", municipality: "Silvia" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "760", municipality: "Sotara" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "780", municipality: "Suárez" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "785", municipality: "Sucre" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "807", municipality: "Timbío" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "809", municipality: "Timbiquí" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "821", municipality: "Toribio" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "824", municipality: "Totoró" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "845", municipality: "Villa Rica" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "001", municipality: "Valledupar" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "011", municipality: "Aguachica" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "013", municipality: "Agustín Codazzi" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "032", municipality: "Astrea" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "045", municipality: "Becerril" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "060", municipality: "Bosconia" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "175", municipality: "Chimichagua" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "178", municipality: "Chiriguaná" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "228", municipality: "Curumaní" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "238", municipality: "El Copey" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "250", municipality: "El Paso" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "295", municipality: "Gamarra" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "310", municipality: "González" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "383", municipality: "La Gloria" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "443", municipality: "Manaure" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "517", municipality: "Pailitas" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "550", municipality: "Pelaya" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "570", municipality: "Pueblo Bello" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "621", municipality: "La Paz" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "710", municipality: "San Alberto" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "750", municipality: "San Diego" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "770", municipality: "San Martín" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "787", municipality: "Tamalameque" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "001", municipality: "Montería" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "068", municipality: "Ayapel" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "079", municipality: "Buenavista" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "090", municipality: "Canalete" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "162", municipality: "Cereté" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "168", municipality: "Chimá" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "182", municipality: "Chinú" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "300", municipality: "Cotorra" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "417", municipality: "Lorica" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "419", municipality: "Los Córdobas" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "464", municipality: "Momil" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "500", municipality: "Moñitos" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "555", municipality: "Planeta Rica" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "570", municipality: "Pueblo Nuevo" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "574", municipality: "Puerto Escondido" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "586", municipality: "Purísima" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "660", municipality: "Sahagún" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "670", municipality: "San Andrés Sotavento" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "672", municipality: "San Antero" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "686", municipality: "San Pelayo" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "807", municipality: "Tierralta" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "815", municipality: "Tuchín" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "855", municipality: "Valencia" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "035", municipality: "Anapoima" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "053", municipality: "Arbeláez" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "086", municipality: "Beltrán" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "095", municipality: "Bituima" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "099", municipality: "Bojacá" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "120", municipality: "Cabrera" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "123", municipality: "Cachipay" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "126", municipality: "Cajicá" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "148", municipality: "Caparrapí" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "151", municipality: "Caqueza" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "168", municipality: "Chaguaní" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "178", municipality: "Chipaque" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "181", municipality: "Choachí" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "183", municipality: "Chocontá" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "200", municipality: "Cogua" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "214", municipality: "Cota" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "224", municipality: "Cucunubá" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "245", municipality: "El Colegio" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "260", municipality: "El Rosal" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "279", municipality: "Fomeque" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "281", municipality: "Fosca" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "286", municipality: "Funza" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "288", municipality: "Fúquene" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "293", municipality: "Gachala" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "295", municipality: "Gachancipá" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "297", municipality: "Gachetá" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "307", municipality: "Girardot" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "312", municipality: "Granada" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "317", municipality: "Guachetá" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "320", municipality: "Guaduas" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "322", municipality: "Guasca" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "324", municipality: "Guataquí" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "326", municipality: "Guatavita" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "335", municipality: "Guayabetal" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "339", municipality: "Gutiérrez" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "368", municipality: "Jerusalén" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "372", municipality: "Junín" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "377", municipality: "La Calera" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "386", municipality: "La Mesa" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "394", municipality: "La Palma" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "398", municipality: "La Peña" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "402", municipality: "La Vega" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "407", municipality: "Lenguazaque" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "426", municipality: "Macheta" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "430", municipality: "Madrid" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "436", municipality: "Manta" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "438", municipality: "Medina" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "473", municipality: "Mosquera" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "483", municipality: "Nariño" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "486", municipality: "Nemocón" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "488", municipality: "Nilo" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "489", municipality: "Nimaima" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "491", municipality: "Nocaima" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "506", municipality: "Venecia" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "513", municipality: "Pacho" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "518", municipality: "Paime" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "524", municipality: "Pandi" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "530", municipality: "Paratebueno" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "535", municipality: "Pasca" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "572", municipality: "Puerto Salgar" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "580", municipality: "Pulí" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "592", municipality: "Quebradanegra" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "594", municipality: "Quetame" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "596", municipality: "Quipile" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "599", municipality: "Apulo" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "612", municipality: "Ricaurte" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "649", municipality: "San Bernardo" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "653", municipality: "San Cayetano" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "658", municipality: "San Francisco" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "736", municipality: "Sesquilé" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "740", municipality: "Sibaté" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "743", municipality: "Silvania" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "745", municipality: "Simijaca" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "754", municipality: "Soacha" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "769", municipality: "Subachoque" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "772", municipality: "Suesca" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "777", municipality: "Supatá" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "779", municipality: "Susa" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "781", municipality: "Sutatausa" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "785", municipality: "Tabio" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "793", municipality: "Tausa" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "797", municipality: "Tena" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "799", municipality: "Tenjo" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "805", municipality: "Tibacuy" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "807", municipality: "Tibirita" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "815", municipality: "Tocaima" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "817", municipality: "Tocancipá" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "823", municipality: "Topaipí" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "839", municipality: "Ubalá" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "841", municipality: "Ubaque" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "845", municipality: "Une" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "851", municipality: "Útica" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "867", municipality: "Vianí" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "871", municipality: "Villagómez" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "873", municipality: "Villapinzón" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "875", municipality: "Villeta" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "878", municipality: "Viotá" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "898", municipality: "Zipacón" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "001", municipality: "Quibdó" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "006", municipality: "Acandí" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "025", municipality: "Alto Baudo" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "050", municipality: "Atrato" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "073", municipality: "Bagadó" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "075", municipality: "Bahía Solano" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "077", municipality: "Bajo Baudó" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "099", municipality: "Bojaya" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "160", municipality: "Cértegui" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "205", municipality: "Condoto" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "372", municipality: "Juradó" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "413", municipality: "Lloró" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "425", municipality: "Medio Atrato" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "430", municipality: "Medio Baudó" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "450", municipality: "Medio San Juan" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "491", municipality: "Nóvita" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "495", municipality: "Nuquí" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "580", municipality: "Río Iro" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "600", municipality: "Río Quito" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "615", municipality: "Riosucio" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "745", municipality: "Sipí" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "800", municipality: "Unguía" },
    { departmentCode: "41", department: "Huila", municipalityCode: "001", municipality: "Neiva" },
    { departmentCode: "41", department: "Huila", municipalityCode: "006", municipality: "Acevedo" },
    { departmentCode: "41", department: "Huila", municipalityCode: "013", municipality: "Agrado" },
    { departmentCode: "41", department: "Huila", municipalityCode: "016", municipality: "Aipe" },
    { departmentCode: "41", department: "Huila", municipalityCode: "020", municipality: "Algeciras" },
    { departmentCode: "41", department: "Huila", municipalityCode: "026", municipality: "Altamira" },
    { departmentCode: "41", department: "Huila", municipalityCode: "078", municipality: "Baraya" },
    { departmentCode: "41", department: "Huila", municipalityCode: "132", municipality: "Campoalegre" },
    { departmentCode: "41", department: "Huila", municipalityCode: "206", municipality: "Colombia" },
    { departmentCode: "41", department: "Huila", municipalityCode: "244", municipality: "Elías" },
    { departmentCode: "41", department: "Huila", municipalityCode: "298", municipality: "Garzón" },
    { departmentCode: "41", department: "Huila", municipalityCode: "306", municipality: "Gigante" },
    { departmentCode: "41", department: "Huila", municipalityCode: "319", municipality: "Guadalupe" },
    { departmentCode: "41", department: "Huila", municipalityCode: "349", municipality: "Hobo" },
    { departmentCode: "41", department: "Huila", municipalityCode: "357", municipality: "Iquira" },
    { departmentCode: "41", department: "Huila", municipalityCode: "359", municipality: "Isnos" },
    { departmentCode: "41", department: "Huila", municipalityCode: "378", municipality: "La Argentina" },
    { departmentCode: "41", department: "Huila", municipalityCode: "396", municipality: "La Plata" },
    { departmentCode: "41", department: "Huila", municipalityCode: "483", municipality: "Nátaga" },
    { departmentCode: "41", department: "Huila", municipalityCode: "503", municipality: "Oporapa" },
    { departmentCode: "41", department: "Huila", municipalityCode: "518", municipality: "Paicol" },
    { departmentCode: "41", department: "Huila", municipalityCode: "524", municipality: "Palermo" },
    { departmentCode: "41", department: "Huila", municipalityCode: "530", municipality: "Palestina" },
    { departmentCode: "41", department: "Huila", municipalityCode: "548", municipality: "Pital" },
    { departmentCode: "41", department: "Huila", municipalityCode: "551", municipality: "Pitalito" },
    { departmentCode: "41", department: "Huila", municipalityCode: "615", municipality: "Rivera" },
    { departmentCode: "41", department: "Huila", municipalityCode: "660", municipality: "Saladoblanco" },
    { departmentCode: "41", department: "Huila", municipalityCode: "676", municipality: "Santa María" },
    { departmentCode: "41", department: "Huila", municipalityCode: "770", municipality: "Suaza" },
    { departmentCode: "41", department: "Huila", municipalityCode: "791", municipality: "Tarqui" },
    { departmentCode: "41", department: "Huila", municipalityCode: "797", municipality: "Tesalia" },
    { departmentCode: "41", department: "Huila", municipalityCode: "799", municipality: "Tello" },
    { departmentCode: "41", department: "Huila", municipalityCode: "801", municipality: "Teruel" },
    { departmentCode: "41", department: "Huila", municipalityCode: "807", municipality: "Timaná" },
    { departmentCode: "41", department: "Huila", municipalityCode: "872", municipality: "Villavieja" },
    { departmentCode: "41", department: "Huila", municipalityCode: "885", municipality: "Yaguará" },
    { departmentCode: "44", department: "La Guajira", municipalityCode: "001", municipality: "Riohacha" },
    { departmentCode: "44", department: "La Guajira", municipalityCode: "035", municipality: "Albania" },
    { departmentCode: "44", department: "La Guajira", municipalityCode: "078", municipality: "Barrancas" },
    { departmentCode: "44", department: "La Guajira", municipalityCode: "090", municipality: "Dibula" },
    { departmentCode: "44", department: "La Guajira", municipalityCode: "098", municipality: "Distracción" },
    { departmentCode: "44", department: "La Guajira", municipalityCode: "110", municipality: "El Molino" },
    { departmentCode: "44", department: "La Guajira", municipalityCode: "279", municipality: "Fonseca" },
    { departmentCode: "44", department: "La Guajira", municipalityCode: "378", municipality: "Hatonuevo" },
    { departmentCode: "44", department: "La Guajira", municipalityCode: "430", municipality: "Maicao" },
    { departmentCode: "44", department: "La Guajira", municipalityCode: "560", municipality: "Manaure" },
    { departmentCode: "44", department: "La Guajira", municipalityCode: "847", municipality: "Uribia" },
    { departmentCode: "44", department: "La Guajira", municipalityCode: "855", municipality: "Urumita" },
    { departmentCode: "44", department: "La Guajira", municipalityCode: "874", municipality: "Villanueva" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "001", municipality: "Santa Marta" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "030", municipality: "Algarrobo" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "053", municipality: "Aracataca" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "058", municipality: "Ariguaní" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "161", municipality: "Cerro San Antonio" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "170", municipality: "Chivolo" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "205", municipality: "Concordia" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "245", municipality: "El Banco" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "258", municipality: "El Piñon" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "268", municipality: "El Retén" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "288", municipality: "Fundación" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "318", municipality: "Guamal" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "460", municipality: "Nueva Granada" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "541", municipality: "Pedraza" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "551", municipality: "Pivijay" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "555", municipality: "Plato" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "605", municipality: "Remolino" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "675", municipality: "Salamina" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "703", municipality: "San Zenón" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "707", municipality: "Santa Ana" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "745", municipality: "Sitionuevo" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "798", municipality: "Tenerife" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "960", municipality: "Zapayán" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "980", municipality: "Zona Bananera" },
    { departmentCode: "50", department: "Meta", municipalityCode: "001", municipality: "Villavicencio" },
    { departmentCode: "50", department: "Meta", municipalityCode: "006", municipality: "Acacias" },
    { departmentCode: "50", department: "Meta", municipalityCode: "124", municipality: "Cabuyaro" },
    { departmentCode: "50", department: "Meta", municipalityCode: "223", municipality: "Cubarral" },
    { departmentCode: "50", department: "Meta", municipalityCode: "226", municipality: "Cumaral" },
    { departmentCode: "50", department: "Meta", municipalityCode: "245", municipality: "El Calvario" },
    { departmentCode: "50", department: "Meta", municipalityCode: "251", municipality: "El Castillo" },
    { departmentCode: "50", department: "Meta", municipalityCode: "270", municipality: "El Dorado" },
    { departmentCode: "50", department: "Meta", municipalityCode: "313", municipality: "Granada" },
    { departmentCode: "50", department: "Meta", municipalityCode: "318", municipality: "Guamal" },
    { departmentCode: "50", department: "Meta", municipalityCode: "325", municipality: "Mapiripán" },
    { departmentCode: "50", department: "Meta", municipalityCode: "330", municipality: "Mesetas" },
    { departmentCode: "50", department: "Meta", municipalityCode: "350", municipality: "La Macarena" },
    { departmentCode: "50", department: "Meta", municipalityCode: "370", municipality: "Uribe" },
    { departmentCode: "50", department: "Meta", municipalityCode: "400", municipality: "Lejanías" },
    { departmentCode: "50", department: "Meta", municipalityCode: "450", municipality: "Puerto Concordia" },
    { departmentCode: "50", department: "Meta", municipalityCode: "568", municipality: "Puerto Gaitán" },
    { departmentCode: "50", department: "Meta", municipalityCode: "573", municipality: "Puerto López" },
    { departmentCode: "50", department: "Meta", municipalityCode: "577", municipality: "Puerto Lleras" },
    { departmentCode: "50", department: "Meta", municipalityCode: "590", municipality: "Puerto Rico" },
    { departmentCode: "50", department: "Meta", municipalityCode: "606", municipality: "Restrepo" },
    { departmentCode: "50", department: "Meta", municipalityCode: "686", municipality: "San Juanito" },
    { departmentCode: "50", department: "Meta", municipalityCode: "689", municipality: "San Martín" },
    { departmentCode: "50", department: "Meta", municipalityCode: "711", municipality: "Vista Hermosa" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "001", municipality: "Pasto" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "019", municipality: "Albán" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "022", municipality: "Aldana" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "036", municipality: "Ancuyá" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "079", municipality: "Barbacoas" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "203", municipality: "Colón" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "207", municipality: "Consaca" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "210", municipality: "Contadero" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "215", municipality: "Córdoba" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "224", municipality: "Cuaspud" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "227", municipality: "Cumbal" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "233", municipality: "Cumbitara" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "250", municipality: "El Charco" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "254", municipality: "El Peñol" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "256", municipality: "El Rosario" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "260", municipality: "El Tambo" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "287", municipality: "Funes" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "317", municipality: "Guachucal" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "320", municipality: "Guaitarilla" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "323", municipality: "Gualmatán" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "352", municipality: "Iles" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "354", municipality: "Imués" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "356", municipality: "Ipiales" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "378", municipality: "La Cruz" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "381", municipality: "La Florida" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "385", municipality: "La Llanada" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "390", municipality: "La Tola" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "399", municipality: "La Unión" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "405", municipality: "Leiva" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "411", municipality: "Linares" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "418", municipality: "Los Andes" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "427", municipality: "Magüí" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "435", municipality: "Mallama" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "473", municipality: "Mosquera" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "480", municipality: "Nariño" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "490", municipality: "Olaya Herrera" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "506", municipality: "Ospina" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "520", municipality: "Francisco Pizarro" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "540", municipality: "Policarpa" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "560", municipality: "Potosí" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "565", municipality: "Providencia" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "573", municipality: "Puerres" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "585", municipality: "Pupiales" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "612", municipality: "Ricaurte" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "621", municipality: "Roberto Payán" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "678", municipality: "Samaniego" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "683", municipality: "Sandoná" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "685", municipality: "San Bernardo" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "687", municipality: "San Lorenzo" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "693", municipality: "San Pablo" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "696", municipality: "Santa Bárbara" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "720", municipality: "Sapuyes" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "786", municipality: "Taminango" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "788", municipality: "Tangua" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "838", municipality: "Túquerres" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "885", municipality: "Yacuanquer" },
    { departmentCode: "63", department: "Quindío", municipalityCode: "001", municipality: "Armenia" },
    { departmentCode: "63", department: "Quindío", municipalityCode: "111", municipality: "Buenavista" },
    { departmentCode: "63", department: "Quindío", municipalityCode: "190", municipality: "Circasia" },
    { departmentCode: "63", department: "Quindío", municipalityCode: "212", municipality: "Córdoba" },
    { departmentCode: "63", department: "Quindío", municipalityCode: "272", municipality: "Filandia" },
    { departmentCode: "63", department: "Quindío", municipalityCode: "401", municipality: "La Tebaida" },
    { departmentCode: "63", department: "Quindío", municipalityCode: "470", municipality: "Montenegro" },
    { departmentCode: "63", department: "Quindío", municipalityCode: "548", municipality: "Pijao" },
    { departmentCode: "63", department: "Quindío", municipalityCode: "594", municipality: "Quimbaya" },
    { departmentCode: "63", department: "Quindío", municipalityCode: "690", municipality: "Salento" },
    { departmentCode: "66", department: "Risaralda", municipalityCode: "001", municipality: "Pereira" },
    { departmentCode: "66", department: "Risaralda", municipalityCode: "045", municipality: "Apía" },
    { departmentCode: "66", department: "Risaralda", municipalityCode: "075", municipality: "Balboa" },
    { departmentCode: "66", department: "Risaralda", municipalityCode: "170", municipality: "Dosquebradas" },
    { departmentCode: "66", department: "Risaralda", municipalityCode: "318", municipality: "Guática" },
    { departmentCode: "66", department: "Risaralda", municipalityCode: "383", municipality: "La Celia" },
    { departmentCode: "66", department: "Risaralda", municipalityCode: "400", municipality: "La Virginia" },
    { departmentCode: "66", department: "Risaralda", municipalityCode: "440", municipality: "Marsella" },
    { departmentCode: "66", department: "Risaralda", municipalityCode: "456", municipality: "Mistrató" },
    { departmentCode: "66", department: "Risaralda", municipalityCode: "572", municipality: "Pueblo Rico" },
    { departmentCode: "66", department: "Risaralda", municipalityCode: "594", municipality: "Quinchía" },
    { departmentCode: "66", department: "Risaralda", municipalityCode: "687", municipality: "Santuario" },
    { departmentCode: "68", department: "Santander", municipalityCode: "001", municipality: "Bucaramanga" },
    { departmentCode: "68", department: "Santander", municipalityCode: "013", municipality: "Aguada" },
    { departmentCode: "68", department: "Santander", municipalityCode: "020", municipality: "Albania" },
    { departmentCode: "68", department: "Santander", municipalityCode: "051", municipality: "Aratoca" },
    { departmentCode: "68", department: "Santander", municipalityCode: "077", municipality: "Barbosa" },
    { departmentCode: "68", department: "Santander", municipalityCode: "079", municipality: "Barichara" },
    { departmentCode: "68", department: "Santander", municipalityCode: "081", municipality: "Barrancabermeja" },
    { departmentCode: "68", department: "Santander", municipalityCode: "092", municipality: "Betulia" },
    { departmentCode: "68", department: "Santander", municipalityCode: "101", municipality: "Bolívar" },
    { departmentCode: "68", department: "Santander", municipalityCode: "121", municipality: "Cabrera" },
    { departmentCode: "68", department: "Santander", municipalityCode: "132", municipality: "California" },
    { departmentCode: "68", department: "Santander", municipalityCode: "152", municipality: "Carcasí" },
    { departmentCode: "68", department: "Santander", municipalityCode: "160", municipality: "Cepitá" },
    { departmentCode: "68", department: "Santander", municipalityCode: "162", municipality: "Cerrito" },
    { departmentCode: "68", department: "Santander", municipalityCode: "167", municipality: "Charalá" },
    { departmentCode: "68", department: "Santander", municipalityCode: "169", municipality: "Charta" },
    { departmentCode: "68", department: "Santander", municipalityCode: "179", municipality: "Chipatá" },
    { departmentCode: "68", department: "Santander", municipalityCode: "190", municipality: "Cimitarra" },
    { departmentCode: "68", department: "Santander", municipalityCode: "207", municipality: "Concepción" },
    { departmentCode: "68", department: "Santander", municipalityCode: "209", municipality: "Confines" },
    { departmentCode: "68", department: "Santander", municipalityCode: "211", municipality: "Contratación" },
    { departmentCode: "68", department: "Santander", municipalityCode: "217", municipality: "Coromoro" },
    { departmentCode: "68", department: "Santander", municipalityCode: "229", municipality: "Curití" },
    { departmentCode: "68", department: "Santander", municipalityCode: "245", municipality: "El Guacamayo" },
    { departmentCode: "68", department: "Santander", municipalityCode: "255", municipality: "El Playón" },
    { departmentCode: "68", department: "Santander", municipalityCode: "264", municipality: "Encino" },
    { departmentCode: "68", department: "Santander", municipalityCode: "266", municipality: "Enciso" },
    { departmentCode: "68", department: "Santander", municipalityCode: "271", municipality: "Florián" },
    { departmentCode: "68", department: "Santander", municipalityCode: "276", municipality: "Floridablanca" },
    { departmentCode: "68", department: "Santander", municipalityCode: "296", municipality: "Galán" },
    { departmentCode: "68", department: "Santander", municipalityCode: "298", municipality: "Gambita" },
    { departmentCode: "68", department: "Santander", municipalityCode: "307", municipality: "Girón" },
    { departmentCode: "68", department: "Santander", municipalityCode: "318", municipality: "Guaca" },
    { departmentCode: "68", department: "Santander", municipalityCode: "320", municipality: "Guadalupe" },
    { departmentCode: "68", department: "Santander", municipalityCode: "322", municipality: "Guapotá" },
    { departmentCode: "68", department: "Santander", municipalityCode: "324", municipality: "Guavatá" },
    { departmentCode: "68", department: "Santander", municipalityCode: "327", municipality: "Güepsa" },
    { departmentCode: "68", department: "Santander", municipalityCode: "368", municipality: "Jesús María" },
    { departmentCode: "68", department: "Santander", municipalityCode: "370", municipality: "Jordán" },
    { departmentCode: "68", department: "Santander", municipalityCode: "377", municipality: "La Belleza" },
    { departmentCode: "68", department: "Santander", municipalityCode: "385", municipality: "Landázuri" },
    { departmentCode: "68", department: "Santander", municipalityCode: "397", municipality: "La Paz" },
    { departmentCode: "68", department: "Santander", municipalityCode: "406", municipality: "Lebríja" },
    { departmentCode: "68", department: "Santander", municipalityCode: "418", municipality: "Los Santos" },
    { departmentCode: "68", department: "Santander", municipalityCode: "425", municipality: "Macaravita" },
    { departmentCode: "68", department: "Santander", municipalityCode: "432", municipality: "Málaga" },
    { departmentCode: "68", department: "Santander", municipalityCode: "444", municipality: "Matanza" },
    { departmentCode: "68", department: "Santander", municipalityCode: "464", municipality: "Mogotes" },
    { departmentCode: "68", department: "Santander", municipalityCode: "468", municipality: "Molagavita" },
    { departmentCode: "68", department: "Santander", municipalityCode: "498", municipality: "Ocamonte" },
    { departmentCode: "68", department: "Santander", municipalityCode: "500", municipality: "Oiba" },
    { departmentCode: "68", department: "Santander", municipalityCode: "502", municipality: "Onzaga" },
    { departmentCode: "68", department: "Santander", municipalityCode: "522", municipality: "Palmar" },
    { departmentCode: "68", department: "Santander", municipalityCode: "533", municipality: "Páramo" },
    { departmentCode: "68", department: "Santander", municipalityCode: "547", municipality: "Piedecuesta" },
    { departmentCode: "68", department: "Santander", municipalityCode: "549", municipality: "Pinchote" },
    { departmentCode: "68", department: "Santander", municipalityCode: "572", municipality: "Puente Nacional" },
    { departmentCode: "68", department: "Santander", municipalityCode: "615", municipality: "Rionegro" },
    { departmentCode: "68", department: "Santander", municipalityCode: "669", municipality: "San Andrés" },
    { departmentCode: "68", department: "Santander", municipalityCode: "679", municipality: "San Gil" },
    { departmentCode: "68", department: "Santander", municipalityCode: "682", municipality: "San Joaquín" },
    { departmentCode: "68", department: "Santander", municipalityCode: "686", municipality: "San Miguel" },
    { departmentCode: "68", department: "Santander", municipalityCode: "705", municipality: "Santa Bárbara" },
    { departmentCode: "68", department: "Santander", municipalityCode: "745", municipality: "Simacota" },
    { departmentCode: "68", department: "Santander", municipalityCode: "755", municipality: "Socorro" },
    { departmentCode: "68", department: "Santander", municipalityCode: "770", municipality: "Suaita" },
    { departmentCode: "68", department: "Santander", municipalityCode: "773", municipality: "Sucre" },
    { departmentCode: "68", department: "Santander", municipalityCode: "780", municipality: "Suratá" },
    { departmentCode: "68", department: "Santander", municipalityCode: "820", municipality: "Tona" },
    { departmentCode: "68", department: "Santander", municipalityCode: "861", municipality: "Vélez" },
    { departmentCode: "68", department: "Santander", municipalityCode: "867", municipality: "Vetas" },
    { departmentCode: "68", department: "Santander", municipalityCode: "872", municipality: "Villanueva" },
    { departmentCode: "68", department: "Santander", municipalityCode: "895", municipality: "Zapatoca" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "001", municipality: "Sincelejo" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "110", municipality: "Buenavista" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "124", municipality: "Caimito" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "204", municipality: "Coloso" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "221", municipality: "Coveñas" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "230", municipality: "Chalán" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "233", municipality: "El Roble" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "235", municipality: "Galeras" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "265", municipality: "Guaranda" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "400", municipality: "La Unión" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "418", municipality: "Los Palmitos" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "429", municipality: "Majagual" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "473", municipality: "Morroa" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "508", municipality: "Ovejas" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "523", municipality: "Palmito" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "678", municipality: "San Benito Abad" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "708", municipality: "San Marcos" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "713", municipality: "San Onofre" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "717", municipality: "San Pedro" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "771", municipality: "Sucre" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "823", municipality: "Tolú Viejo" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "024", municipality: "Alpujarra" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "026", municipality: "Alvarado" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "030", municipality: "Ambalema" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "055", municipality: "Armero" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "067", municipality: "Ataco" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "124", municipality: "Cajamarca" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "168", municipality: "Chaparral" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "200", municipality: "Coello" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "217", municipality: "Coyaima" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "226", municipality: "Cunday" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "236", municipality: "Dolores" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "268", municipality: "Espinal" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "270", municipality: "Falan" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "275", municipality: "Flandes" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "283", municipality: "Fresno" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "319", municipality: "Guamo" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "347", municipality: "Herveo" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "349", municipality: "Honda" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "352", municipality: "Icononzo" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "443", municipality: "Mariquita" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "449", municipality: "Melgar" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "461", municipality: "Murillo" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "483", municipality: "Natagaima" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "504", municipality: "Ortega" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "520", municipality: "Palocabildo" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "547", municipality: "Piedras" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "555", municipality: "Planadas" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "563", municipality: "Prado" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "585", municipality: "Purificación" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "616", municipality: "Rio Blanco" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "622", municipality: "Roncesvalles" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "624", municipality: "Rovira" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "671", municipality: "Saldaña" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "686", municipality: "Santa Isabel" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "861", municipality: "Venadillo" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "870", municipality: "Villahermosa" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "873", municipality: "Villarrica" },
    { departmentCode: "81", department: "Arauca", municipalityCode: "065", municipality: "Arauquita" },
    { departmentCode: "81", department: "Arauca", municipalityCode: "220", municipality: "Cravo Norte" },
    { departmentCode: "81", department: "Arauca", municipalityCode: "300", municipality: "Fortul" },
    { departmentCode: "81", department: "Arauca", municipalityCode: "591", municipality: "Puerto Rondón" },
    { departmentCode: "81", department: "Arauca", municipalityCode: "736", municipality: "Saravena" },
    { departmentCode: "81", department: "Arauca", municipalityCode: "794", municipality: "Tame" },
    { departmentCode: "81", department: "Arauca", municipalityCode: "001", municipality: "Arauca" },
    { departmentCode: "85", department: "Casanare", municipalityCode: "001", municipality: "Yopal" },
    { departmentCode: "85", department: "Casanare", municipalityCode: "010", municipality: "Aguazul" },
    { departmentCode: "85", department: "Casanare", municipalityCode: "015", municipality: "Chámeza" },
    { departmentCode: "85", department: "Casanare", municipalityCode: "125", municipality: "Hato Corozal" },
    { departmentCode: "85", department: "Casanare", municipalityCode: "136", municipality: "La Salina" },
    { departmentCode: "85", department: "Casanare", municipalityCode: "162", municipality: "Monterrey" },
    { departmentCode: "85", department: "Casanare", municipalityCode: "263", municipality: "Pore" },
    { departmentCode: "85", department: "Casanare", municipalityCode: "279", municipality: "Recetor" },
    { departmentCode: "85", department: "Casanare", municipalityCode: "300", municipality: "Sabanalarga" },
    { departmentCode: "85", department: "Casanare", municipalityCode: "315", municipality: "Sácama" },
    { departmentCode: "85", department: "Casanare", municipalityCode: "410", municipality: "Tauramena" },
    { departmentCode: "85", department: "Casanare", municipalityCode: "430", municipality: "Trinidad" },
    { departmentCode: "85", department: "Casanare", municipalityCode: "440", municipality: "Villanueva" },
    { departmentCode: "86", department: "Putumayo", municipalityCode: "001", municipality: "Mocoa" },
    { departmentCode: "86", department: "Putumayo", municipalityCode: "219", municipality: "Colón" },
    { departmentCode: "86", department: "Putumayo", municipalityCode: "320", municipality: "Orito" },
    { departmentCode: "86", department: "Putumayo", municipalityCode: "569", municipality: "Puerto Caicedo" },
    { departmentCode: "86", department: "Putumayo", municipalityCode: "571", municipality: "Puerto Guzmán" },
    { departmentCode: "86", department: "Putumayo", municipalityCode: "573", municipality: "Leguízamo" },
    { departmentCode: "86", department: "Putumayo", municipalityCode: "749", municipality: "Sibundoy" },
    { departmentCode: "86", department: "Putumayo", municipalityCode: "755", municipality: "San Francisco" },
    { departmentCode: "86", department: "Putumayo", municipalityCode: "757", municipality: "San Miguel" },
    { departmentCode: "86", department: "Putumayo", municipalityCode: "760", municipality: "Santiago" },
    { departmentCode: "91", department: "Amazonas", municipalityCode: "001", municipality: "Leticia" },
    { departmentCode: "91", department: "Amazonas", municipalityCode: "263", municipality: "El Encanto" },
    { departmentCode: "91", department: "Amazonas", municipalityCode: "405", municipality: "La Chorrera" },
    { departmentCode: "91", department: "Amazonas", municipalityCode: "407", municipality: "La Pedrera" },
    { departmentCode: "91", department: "Amazonas", municipalityCode: "430", municipality: "La Victoria" },
    { departmentCode: "91", department: "Amazonas", municipalityCode: "536", municipality: "Puerto Arica" },
    { departmentCode: "91", department: "Amazonas", municipalityCode: "540", municipality: "Puerto Nariño" },
    { departmentCode: "91", department: "Amazonas", municipalityCode: "669", municipality: "Puerto Santander" },
    { departmentCode: "91", department: "Amazonas", municipalityCode: "798", municipality: "Tarapacá" },
    { departmentCode: "94", department: "Guainía", municipalityCode: "001", municipality: "Inírida" },
    { departmentCode: "94", department: "Guainía", municipalityCode: "343", municipality: "Barranco Minas" },
    { departmentCode: "94", department: "Guainía", municipalityCode: "663", municipality: "Mapiripana" },
    { departmentCode: "94", department: "Guainía", municipalityCode: "883", municipality: "San Felipe" },
    { departmentCode: "94", department: "Guainía", municipalityCode: "884", municipality: "Puerto Colombia" },
    { departmentCode: "94", department: "Guainía", municipalityCode: "885", municipality: "La Guadalupe" },
    { departmentCode: "94", department: "Guainía", municipalityCode: "886", municipality: "Cacahual" },
    { departmentCode: "94", department: "Guainía", municipalityCode: "887", municipality: "Pana Pana" },
    { departmentCode: "94", department: "Guainía", municipalityCode: "888", municipality: "Morichal" },
    { departmentCode: "97", department: "Vaupés", municipalityCode: "001", municipality: "Mitú" },
    { departmentCode: "97", department: "Vaupés", municipalityCode: "161", municipality: "Caruru" },
    { departmentCode: "97", department: "Vaupés", municipalityCode: "511", municipality: "Pacoa" },
    { departmentCode: "97", department: "Vaupés", municipalityCode: "666", municipality: "Taraira" },
    { departmentCode: "97", department: "Vaupés", municipalityCode: "777", municipality: "Papunaua" },
    { departmentCode: "99", department: "Vichada", municipalityCode: "001", municipality: "Puerto Carreño" },
    { departmentCode: "99", department: "Vichada", municipalityCode: "524", municipality: "La Primavera" },
    { departmentCode: "99", department: "Vichada", municipalityCode: "624", municipality: "Santa Rosalía" },
    { departmentCode: "99", department: "Vichada", municipalityCode: "773", municipality: "Cumaribo" },
    { departmentCode: "18", department: "Caquetá", municipalityCode: "610", municipality: "San José del Fragua" },
    { departmentCode: "50", department: "Meta", municipalityCode: "110", municipality: "Barranca de Upía" },
    { departmentCode: "68", department: "Santander", municipalityCode: "524", municipality: "Palmas del Socorro" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "662", municipality: "San Juan de Río Seco" },
    { departmentCode: "08", department: "Atlántico", municipalityCode: "372", municipality: "Juan de Acosta" },
    { departmentCode: "50", department: "Meta", municipalityCode: "287", municipality: "Fuente de Oro" },
    { departmentCode: "85", department: "Casanare", municipalityCode: "325", municipality: "San Luis de Gaceno" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "250", municipality: "El Litoral del San Juan" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "843", municipality: "Villa de San Diego de Ubate" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "074", municipality: "Barranco de Loba" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "816", municipality: "Togüí" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "688", municipality: "Santa Rosa del Sur" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "135", municipality: "El Cantón del San Pablo" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "407", municipality: "Villa de Leyva" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "692", municipality: "San Sebastián de Buenavista" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "537", municipality: "Paz de Río" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "300", municipality: "Hatillo de Loba" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "660", municipality: "Sabanas de San Angel" },
    { departmentCode: "95", department: "Guaviare", municipalityCode: "015", municipality: "Calamar" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "614", municipality: "Río de Oro" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "665", municipality: "San Pedro de Uraba" },
    { departmentCode: "95", department: "Guaviare", municipalityCode: "001", municipality: "San José del Guaviare" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "693", municipality: "Santa Rosa de Viterbo" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "698", municipality: "Santander de Quilichao" },
    { departmentCode: "95", department: "Guaviare", municipalityCode: "200", municipality: "Miraflores" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "042", municipality: "Santafé de Antioquia" },
    { departmentCode: "50", department: "Meta", municipalityCode: "680", municipality: "San Carlos de Guaroa" },
    { departmentCode: "08", department: "Atlántico", municipalityCode: "520", municipality: "Palmar de Varela" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "686", municipality: "Santa Rosa de Osos" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "647", municipality: "San Andrés de Cuerquía" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "854", municipality: "Valle de San Juan" },
    { departmentCode: "68", department: "Santander", municipalityCode: "689", municipality: "San Vicente de Chucurí" },
    { departmentCode: "68", department: "Santander", municipalityCode: "684", municipality: "San José de Miranda" },
    { departmentCode: "88", department: "Archipiélago de San Andrés, Providencia y Santa Catalina", municipalityCode: "564", municipality: "Providencia" },
    { departmentCode: "66", department: "Risaralda", municipalityCode: "682", municipality: "Santa Rosa de Cabal" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "328", municipality: "Guayabal de Siquima" },
    { departmentCode: "18", department: "Caquetá", municipalityCode: "094", municipality: "Belén de Los Andaquies" },
    { departmentCode: "85", department: "Casanare", municipalityCode: "250", municipality: "Paz de Ariporo" },
    { departmentCode: "68", department: "Santander", municipalityCode: "720", municipality: "Santa Helena del Opón" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "681", municipality: "San Pablo de Borbur" },
    { departmentCode: "44", department: "La Guajira", municipalityCode: "420", municipality: "La Jagua del Pilar" },
    { departmentCode: "20", department: "Cesar", municipalityCode: "400", municipality: "La Jagua de Ibirico" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "742", municipality: "San Luis de Sincé" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "667", municipality: "San Luis de Gaceno" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "244", municipality: "El Carmen de Bolívar" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "245", municipality: "El Carmen de Atrato" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "702", municipality: "San Juan de Betulia" },
    { departmentCode: "47", department: "Magdalena", municipalityCode: "545", municipality: "Pijiño del Carmen" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "873", municipality: "Vigía del Fuerte" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "667", municipality: "San Martín de Loba" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "030", municipality: "Altos del Rosario" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "148", municipality: "Carmen de Apicala" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "645", municipality: "San Antonio del Tequendama" },
    { departmentCode: "68", department: "Santander", municipalityCode: "655", municipality: "Sabana de Torres" },
    { departmentCode: "95", department: "Guaviare", municipalityCode: "025", municipality: "El Retorno" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "682", municipality: "San José de Uré" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "694", municipality: "San Pedro de Cartago" },
    { departmentCode: "08", department: "Atlántico", municipalityCode: "137", municipality: "Campo de La Cruz" },
    { departmentCode: "50", department: "Meta", municipalityCode: "683", municipality: "San Juan de Arama" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "658", municipality: "San José de La Montaña" },
    { departmentCode: "18", department: "Caquetá", municipalityCode: "150", municipality: "Cartagena del Chairá" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "660", municipality: "San José del Palmar" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "001", municipality: "Agua de Dios" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "655", municipality: "San Jacinto del Cauca" },
    { departmentCode: "41", department: "Huila", municipalityCode: "668", municipality: "San Agustín" },
    { departmentCode: "52", department: "Nariño", municipalityCode: "258", municipality: "El Tablón de Gómez" },
    { departmentCode: "88", department: "Archipiélago de San Andrés, Providencia y Santa Catalina", municipalityCode: "001", municipality: "San Andrés" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "664", municipality: "San José de Pare" },
    { departmentCode: "86", department: "Putumayo", municipalityCode: "865", municipality: "Valle de Guamez" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "670", municipality: "San Pablo de Borbur" },
    { departmentCode: "70", department: "Sucre", municipalityCode: "820", municipality: "Santiago de Tolú" },
    { departmentCode: "11", department: "Bogotá D.C.", municipalityCode: "001", municipality: "Bogotá D.C." },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "154", municipality: "Carmen de Carupa" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "189", municipality: "Ciénaga de Oro" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "659", municipality: "San Juan de Urabá" },
    { departmentCode: "44", department: "La Guajira", municipalityCode: "650", municipality: "San Juan del Cesar" },
    { departmentCode: "68", department: "Santander", municipalityCode: "235", municipality: "El Carmen de Chucurí" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "148", municipality: "El Carmen de Viboral" },
    { departmentCode: "66", department: "Risaralda", municipalityCode: "088", municipality: "Belén de Umbría" },
    { departmentCode: "27", department: "Chocó", municipalityCode: "086", municipality: "Belén de Bajira" },
    { departmentCode: "68", department: "Santander", municipalityCode: "855", municipality: "Valle de San José" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "678", municipality: "San Luis" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "676", municipality: "San Miguel de Sema" },
    { departmentCode: "73", department: "Tolima", municipalityCode: "675", municipality: "San Antonio" },
    { departmentCode: "68", department: "Santander", municipalityCode: "673", municipality: "San Benito" },
    { departmentCode: "25", department: "Cundinamarca", municipalityCode: "862", municipality: "Vergara" },
    { departmentCode: "23", department: "Córdoba", municipalityCode: "678", municipality: "San Carlos" },
    { departmentCode: "91", department: "Amazonas", municipalityCode: "530", municipality: "Puerto Alegría" },
    { departmentCode: "68", department: "Santander", municipalityCode: "344", municipality: "Hato" },
    { departmentCode: "13", department: "Bolívar", municipalityCode: "654", municipality: "San Jacinto" },
    { departmentCode: "19", department: "Cauca", municipalityCode: "693", municipality: "San Sebastián" },
    { departmentCode: "05", department: "Antioquia", municipalityCode: "649", municipality: "San Carlos" },
    { departmentCode: "15", department: "Boyacá", municipalityCode: "837", municipality: "Tuta" },
    { departmentCode: "54", department: "Norte de Santander", municipalityCode: "743", municipality: "Silos" },
    { departmentCode: "54", department: "Norte de Santander", municipalityCode: "125", municipality: "Cácota" },
    { departmentCode: "76", department: "Valle del Cauca", municipalityCode: "250", municipality: "El Dovio" },
    { departmentCode: "54", department: "Norte de Santander", municipalityCode: "820", municipality: "Toledo" },
    { departmentCode: "76", department: "Valle del Cauca", municipalityCode: "622", municipality: "Roldanillo" },
    { departmentCode: "54", department: "Norte de Santander", municipalityCode: "480", municipality: "Mutiscua" },
    { departmentCode: "76", department: "Valle del Cauca", municipalityCode: "054", municipality: "Argelia" },
    { departmentCode: "54", department: "Norte de Santander", municipalityCode: "261", municipality: "El Zulia" },
    { departmentCode: "54", department: "Norte de Santander", municipalityCode: "660", municipality: "Salazar" },
    { departmentCode: "76", department: "Valle del Cauca", municipalityCode: "736", municipality: "Sevilla" },
    { departmentCode: "76", department: "Valle del Cauca", municipalityCode: "895", municipality: "Zarzal" },
    { departmentCode: "54", department: "Norte de Santander", municipalityCode: "223", municipality: "Cucutilla" },
    { departmentCode: "76", department: "Valle del Cauca", municipalityCode: "248", municipality: "El Cerrito" },
    { departmentCode: "76", department: "Valle del Cauca", municipalityCode: "147", municipality: "Cartago" },
    { departmentCode: "76", department: "Valle del Cauca", municipalityCode: "122", municipality: "Caicedonia" },
    { departmentCode: "54", department: "Norte de Santander", municipalityCode: "553", municipality: "Puerto Santander" },
    { departmentCode: "54", department: "Norte de Santander", municipalityCode: "313", municipality: "Gramalote" },
    { departmentCode: "76", department: "Valle del Cauca", municipalityCode: "246", municipality: "El Cairo" },
    { departmentCode: "54", department: "Norte de Santander", municipalityCode: "250", municipality: "El Tarra" },
    { departmentCode: "76", department: "Valle del Cauca", municipalityCode: "400", municipality: "La Unión" },
    { departmentCode: "76", department: "Valle del Cauca", municipalityCode: "606", municipality: "Restrepo" },
    { departmentCode: "54", department: "Norte de Santander", municipalityCode: "800", municipality: "Teorama" },
    { departmentCode: "76", department: "Valle del Cauca", municipalityCode: "233", municipality: "Dagua" },
    { departmentCode: "54", department: "Norte de Santander", municipalityCode: "051", municipality: "Arboledas" },
    { departmentCode: "76", department: "Valle del Cauca", municipalityCode: "318", municipality: "Guacarí" },
    { departmentCode: "54", department: "Norte de Santander", municipalityCode: "418", municipality: "Lourdes" },
];
