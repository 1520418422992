import customNumeral from "./numberFormat/moneyFormat";
import customMoment from "./momentFormat/dateFormat";
import { getFetchError } from "../services/fetchApi";
import { BusinessException } from "../store/types";
import { deleteData, StoreKey } from "../store/index";

export const deleteLocalStorage = () => {
    for (const property in StoreKey) {
        deleteData(property);
    }
};

export const getModelFullName = (model: { firstName: string; lastName: string }) => {
    return model ? model.firstName + " " + model.lastName : "";
};

export const getUtcDate = (stringDate?: string) => {
    if (!stringDate) return new Date();
    if (stringDate[stringDate.length - 1] === "Z") return new Date(stringDate);
    return new Date(`${stringDate}Z`);
};
export const datetime7FormatUtc = `YYYY-MM-DDTHH:mm:ss:sssssssZ`;

export const getDateFromNow = (value?: Date) => {
    if (!value) return " - ";
    const dateutc = getUtcDate(value.toString());
    return customMoment(dateutc).fromNow();
};
export const getDateFormatCalendar = (value?: Date) => {
    if (!value) return " - ";
    return customMoment(getUtcDate(value.toString())).calendar();
};
export const getDateFormatL = (value?: Date) => {
    if (!value) return " - ";
    return customMoment(getUtcDate(value.toString())).format("LLLL");
};
export const getDateFormatll = (value?: Date) => {
    if (!value) return " - ";
    return customMoment(getUtcDate(value.toString())).format("ll");
};
export const getDateFormatlll = (value?: Date) => {
    if (!value) return " - ";
    return customMoment(getUtcDate(value.toString())).format("lll");
};
export const getDateFormatLLLL = (value?: Date) => {
    if (!value) return " - ";
    return customMoment(getUtcDate(value.toString())).format("LLLL");
};
export const getDateFormatllll = (value?: Date) => {
    if (!value) return " - ";
    return customMoment(getUtcDate(value.toString())).format("llll");
};
export const getDateFormat = (value: Date, format: string) => {
    if (!value) return " - ";
    return customMoment(getUtcDate(value.toString())).format(format);
};

export const getTimeInterval = (end?: Date, start?: Date) => {
    if (!end || !start) return "";

    const endDate = customMoment(getUtcDate(end.toString()));
    const startDate = customMoment(getUtcDate(start.toString()));
    var duration = customMoment.duration(endDate.diff(startDate));
    var timeInterval = Math.abs(Math.round(duration.asMinutes()));

    if (timeInterval <= 60) {
        return `${timeInterval} min`;
    }

    let hoursAndMinutes = Math.abs(duration.asHours());
    let hours = Math.abs(Math.trunc(hoursAndMinutes));
    let minutes = Math.abs(Math.trunc((hoursAndMinutes % hours) * 60));

    return `${hours}h, ${minutes} min`;
};

export const getTimeIntervalSeconds = (end?: Date | string, start?: Date | string) => {
    if (!end || !start) return 0;
    const endDate = customMoment(getUtcDate(end.toString()));
    const startDate = customMoment(getUtcDate(start.toString()));
    var duration = customMoment.duration(endDate.diff(startDate));
    var seconds = Math.abs(Math.trunc(duration.asSeconds()));
    return seconds;
};

export const joinUrl = (base: string, value: string) => {
    if (base && base.charAt(base.length - 1) === "/") {
        return base + value;
    } else return `${base}/${value}`;
};

export const addParamToUrl = (urlBase: string, paramName: string, paramValue: string) => {
    var url = new URL(urlBase);
    var search_params = url.searchParams;
    search_params.set(paramName, paramValue);
    url.search = search_params.toString();

    return url.toString();
};

export const isBunissesException = (data: any) => {
    return data && data.type && data.message && data.code;
};

export const setCookieAlreadyEncrypted = (name: string, value: any) => {
    if (value) {
        var cookie = name + "=" + value + "; Max-Age=2592000";
        document.cookie = cookie;
    }
};

export const deleteCookie = (name: string) => {
    document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
};

export const capitalize = (value: string) => {
    if (typeof value !== "string") return "";
    return value.charAt(0).toUpperCase() + value.slice(1);
};

export const removeFirstCapitalize = (value: string) => {
    if (typeof value !== "string") return "";
    return value.charAt(0).toLowerCase() + value.slice(1);
};

export const stringIsNullOrEmpty = (value?: string) => {
    return !value || value === "" || value === null;
};

export const formatMoney = (value?: string | number) => {
    var number = customNumeral(value ? value : 0);
    return number.format("$0,0.[00]");
};

export const formatNumber = (value: string | number) => {
    var number = customNumeral(value);
    return number.format("0,0.[00]");
};

export const getRandomString = () => {
    let r = Math.random().toString(36).substring(7);
    return r;
};
export const getElementYPosition = (containerId: string, plus: number, defaultValue: number) => {
    let element = document.getElementById(containerId);
    var posi = element?.getBoundingClientRect();
    if (posi) {
        let topposition = posi?.y;
        topposition = topposition === 0 ? defaultValue - plus : topposition;
        return topposition + plus;
    }
    return defaultValue;
};

export const wahioStringFormat = (value: string, ...params: any[]) => {
    return value.replace(/{(\d+)}/g, function (match, number) {
        return typeof params[number] != "undefined" ? params[number] : match;
    });
};

export const getExceptionFromError = (error: any) => {
    var errResult = getFetchError(error);
    let data = errResult.data ? errResult.data : {};
    if (typeof data === "string") {
        data = { message: data };
    }
    return new BusinessException(data.code, data.message, data.type, errResult.status);
};

export const lisetenerContainerResize = (setContainerWidth: (value: number) => void, containerId: string) => {
    let element = document.getElementById(containerId);
    if (!element) return;
    window.addEventListener("resize", containerResize);
    function containerResize() {
        if (element) setContainerWidth(element?.clientWidth);
    }
};

export const lisetenerContainerPosition = (setContainerWidth: (value: number) => void, containerId: string) => {
    let element = document.getElementById(containerId);
    if (!element) return;
    element.addEventListener("resize", containerResize);
    function containerResize() {
        if (element) setContainerWidth(element?.getBoundingClientRect().x);
    }
};

export const getInitialWidth = (containerId: string) => {
    let element = document.getElementById(containerId);
    return element ? element.clientWidth : 1000;
};

export const getInitialPosition = (containerId: string) => {
    let element = document.getElementById(containerId);
    return element ? element?.getBoundingClientRect().x : 1000;
};
