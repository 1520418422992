import styled from "styled-components";

export const HomeContainerCenter = styled.div`
    padding: 20px;
    max-width: 700px;
    margin: auto;
`;

export const HomeBody = styled.div`
    margin-top: 20px;
    padding: 50px 40px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid #8080802e;
    .title-container {
        text-align: center;
        margin-top: 40px;
        margin-bottom: 40px;
        .title {
            margin: 0px;
        }
        .content  { 
            color: ${(props) => props.theme.palette.textLight};
            margin: 0px;
            margin-top: 4px;
        }
    }
    .image {
        width: 100%;
        height: 324px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
`;

export const TrackingContainer = styled.form`
    width: 100%;
    display: flex;

    border: 2px solid #f2f2f2;
    &.active {
        border: 2px solid ${(props) => props.theme.palette.primary};
    }
    border-radius: 10px;
    overflow: hidden;

    a {
        display: contents;
    }
    button {
        border-radius: 0px;
    }
    input {
        width: 100%;
        background: white;
        border: 0px;
        border-radius: 0px;
        font-size: 1.5rem;
        padding: 12px;
    }
`;
