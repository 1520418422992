import styled from "styled-components";
import { breakpoint } from "../../../theme/themeProvider";

interface IButtonRounded {
    rounded?: boolean;
}

export const DefaultButton = styled.button`
    background: ${(props) => props.theme.palette.background};
    outline: transparent;
    padding: 0.5rem 1rem;
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    color: ${(props) => props.theme.palette.text};
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    white-space: nowrap;

    [class^="wahioicon"] {
        margin-right: 10px;
    }

    border-radius: ${(props: IButtonRounded) => (props.rounded ? "50px" : "3px")};
    justify-content: center;
    align-items: center;
    &:disabled {
        background: ${(props) => props.theme.palette.backgroundLight};
        color: ${(props) => props.theme.palette.backgroundDark};
        border-color: transparent;
        &:hover {
            background: ${(props) => props.theme.palette.backgroundLight};
            color: ${(props) => props.theme.palette.backgroundDark};
        }
    }
    &:hover {
        transition: background 0.3s;
        background: ${(props) => props.theme.palette.backgroundDark};
    }
`;

export const DefaultButtonFill = styled(DefaultButton)`
    background: ${(props) => props.theme.palette.backgroundDark};
    &:hover {
        background: ${(props) => props.theme.palette.backgroundDarker};
    }
`;

export const PrimaryButton = styled(DefaultButton)`
    color: white;
    background: ${(props) => props.theme.palette.primary};
    border-color: transparent;
    &:hover {
        background: ${(props) => props.theme.palette.primaryDark};
    }
`;

export const PrimaryButtonMobile = styled(PrimaryButton)`
    .icon {
        display: flex;
        margin: 0;
        font-size: 1.3rem;
    }
    .title {
        display: none;
    }
    position: fixed;
    z-index: 3;
    bottom: 23px;
    right: 13px;
    padding: 15px;
    box-shadow: -3px -3px 11px ${(props) => props.theme.palette.shadowDark};

    ${breakpoint("md")`
        .title {
            display: flex;
        }
        .icon {
            display: none;
        }
        position: initial;
        z-index: 3;
        bottom: 23px;
        right: 13px;
        padding: 0.5rem 1rem;
        box-shadow: none;
    `}
`;

export const PrimaryButton2 = styled(DefaultButton)`
    color: white;
    background: ${(props) => props.theme.palette.primary2};
    border-color: transparent;
    &:hover {
        background: ${(props) => props.theme.palette.primaryDark2};
    }
`;

export const PrimaryButton3 = styled(DefaultButton)`
    color: white;
    background: #673ab7;
    border-color: transparent;
    &:hover {
        background: #3c206f;
    }
`;

export const DangerButton = styled(DefaultButton)`
    color: white;
    background: #f44336;
    border-color: transparent;
    &:hover {
        background: #b1261b;
    }
`;

export const SquareButton = styled(DefaultButton)`
    width: 33px;
    height: 33px;
    min-width: 33px;

    [class^="wahioicon"] {
        margin-right: 0px;
    }
    &.small {
        width: 25px;
        height: 25px;
        min-width: 25px;
    }
    padding: 0px;
    border: 1px solid transparent;
    border-radius: 50px;
    outline-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${(props) => props.theme.palette.textLight};
    background: ${(props) => props.theme.palette.backgroundDark};
`;

export const SquareButtonOutline = styled(SquareButton)`
    border: 1px solid ${(props) => props.theme.palette.backgroundDark};
    background: ${(props) => props.theme.palette.background};
    &:hover {
        background: ${(props) => props.theme.palette.backgroundDark};
    }
`;

export const ButtonColumn = styled(DefaultButton)`
    border-radius: 30px;
    border: 0;
    font-size: 0.9rem;
    color: ${(props) => props.theme.palette.textLight};
    [class^="wahioicon"] {
        margin-right: 0px;
    }
    &.active {
        color: ${(props) => props.theme.palette.primary};
    }
`;
