import React, { Suspense } from "react";
import { BrowserRouter, Switch, Route, RouteProps } from "react-router-dom";
import { I18nProvider } from "./i18n";
import AppSettingsProvider from "./appSettings/AppSettingsContext";
import StyledThemeProvider from "./theme/themeProvider";
import GlobalStyled from "./globalStyled";
import LoadingDualRing from "./components/LoadingDualRing";
import PATHS from "./constants/paths";
import { positions, Provider as AlertProvider, transitions } from "react-alert";
import AlertTemplate from "./components/AlertTemplate";
import Home from "./Page/Home";
import ProductProfile from "./components/ProductProfile";
interface IContextProviders {
    children: React.ReactNode;
}

export const alertOptions = {
    timeout: 5000,
    position: positions.BOTTOM_CENTER,
    transition: transitions.SCALE,
};

const ContextProviders = ({ children }: IContextProviders) => {
    return (
        <AppSettingsProvider>
            <I18nProvider>
                <StyledThemeProvider>{children}</StyledThemeProvider>
            </I18nProvider>
        </AppSettingsProvider>
    );
};

interface IRouteValidation extends RouteProps {
    requiredAuth?: boolean;
}

const RouteValidation = (props: IRouteValidation) => {
    return <Route {...props} />;
};

export const App = () => {
    return (
        <ContextProviders>
            <AlertProvider template={AlertTemplate} {...alertOptions}>
                <Suspense fallback={<LoadingDualRing center={true} />}>
                    <BrowserRouter>
                        <GlobalStyled />
                        <Switch>
                            <RouteValidation path="/" exact component={Home} />
                            <RouteValidation path={PATHS.home} exact component={Home} />
                            <RouteValidation path={PATHS.productProfileId()} exact component={ProductProfile} />
                        </Switch>
                    </BrowserRouter>
                </Suspense>
            </AlertProvider>
        </ContextProviders>
    );
};
export default App;
