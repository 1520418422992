import React from "react";
import { SquareButton } from "../_controls";
import { AlerTemplateView } from "./styled";

export default function index({ message, options, style, close }) {
    return (
        <AlerTemplateView style={{ ...style }} className={options.type}>
            {options.type === "info" && <span className="wahioicon-info icon" />}
            {options.type === "success" && <span className="wahioicon-check-circle icon" />}
            {options.type === "error" && <span className="wahioicon-times-circle icon" />}
            <span className="message" style={{ flex: 2 }}>
                {message}
            </span>
            <SquareButton className="close" onClick={close}>
                <span className="wahioicon-times" />
            </SquareButton>
        </AlerTemplateView>
    );
}
