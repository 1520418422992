import appconfig from "./appconfig";
import { joinUrl } from "../utils";
import { IPaginationRequest } from "../store/types";
import { CompanyImageTypes } from "../store/types/accountUser";
import { DeliveryStatus } from "../store/types/deliveryOrder";

const api = appconfig.API_GATEWAY_ENDPOINT;
const localOrderApi = joinUrl(api, "localorder"); //"http://localhost:7071/api";

const getPageRoute = (req: IPaginationRequest) => {
    return `page/${req.currentPage}/limit/${req.pageSize}`;
};

export const loginEndpoint = {
    login: joinUrl(api, `login/api/login`),
};

export const customerEndpoint = {
    get: {
        customersByAccount: joinUrl(api, "customer/api/customers/account/"),
        customer: joinUrl(api, "customer/api/customers/"),
    },
    post: {
        customer: joinUrl(api, `customer/api/customers`),
    },
};

export const localorderEndpoint = {
    get: {
        orderByTrackingNumber: (trackingNumber: string) => joinUrl(localOrderApi, `orders/trackingnumber/${trackingNumber}`),
        sellersByAccount: (accountId: string) => joinUrl(localOrderApi, `sellers/account/${accountId}`),
        channelsByAccount: (accountId: string) => joinUrl(localOrderApi, `orderchannels/account/${accountId}`),
        deliverySummaryByAccount: (accountId: string) => joinUrl(localOrderApi, `deliveryorders/summary/${accountId}`),
        loungesByAccount: (accountId: string) => joinUrl(localOrderApi, `lounges/account/${accountId}`),
        loungeById: (id: string) => joinUrl(localOrderApi, `lounges/${id}`),
        orderTableById: (id: string) => joinUrl(localOrderApi, `ordertables/${id}`),
        orderTableByOrder: (orderId: string) => joinUrl(localOrderApi, `ordertables/order/${orderId}`),
        customersByAccount: (accountId: string) => joinUrl(localOrderApi, `customers/account/${accountId}`),
        customersById: (id: string) => joinUrl(localOrderApi, `customers/${id}`),
        customersDebts: (accountId: string) => joinUrl(localOrderApi, `orders/customers/alldebts/${accountId}`),
        customerSummaryById: (id: string) => joinUrl(localOrderApi, `orders/customers/summary/${id}`),
        invoiceDesignsByAccount: (accountId: string) => joinUrl(localOrderApi, `invoicedesigns/account/${accountId}`),
        accountingCustomerOrders: (customerId: string) => joinUrl(localOrderApi, `accounting/customerorders/${customerId}`),
        invoiceNumerationByAccount: (accountId: string) => joinUrl(localOrderApi, `orderinvoices/numerations/account/${accountId}`),
    },
    post: {
        orderChannel: joinUrl(localOrderApi, "orderchannels"),
        orderTable: joinUrl(localOrderApi, "ordertables"),
        seller: joinUrl(localOrderApi, "sellers"),
        loungeAll: joinUrl(localOrderApi, "lounges/update-all"),
        customer: joinUrl(localOrderApi, "customers"),
        customerImport: joinUrl(localOrderApi, "customers/importcontacts"),
        singlePayment: joinUrl(localOrderApi, "payments"),
        orderUpdateCreateAll: joinUrl(localOrderApi, "orders/updateall"),
        getOrderQuerySearch: joinUrl(localOrderApi, "orders/querysearch"),
        getDeliveryOrderQuerySearch: joinUrl(localOrderApi, "deliveryorders/querysearch"),
        accountingQuerySearch: joinUrl(localOrderApi, "accounting/orders/querysearch"),
        accountingPaymentQuerySearch: joinUrl(localOrderApi, "accounting/orders/payments/querysearch"),
        invoiceDesign: joinUrl(localOrderApi, `invoicedesigns/`),
        deleteOrder: joinUrl(localOrderApi, `orders/deleteorder`),
        invoiceNumeration: joinUrl(localOrderApi, `orderinvoices/numeration`),
        createOrderInvoice: joinUrl(localOrderApi, `orders/createinvoice`),
        customerQuerySearch: joinUrl(localOrderApi, `customers/querysearch`),
    },
    put: {
        seller: joinUrl(localOrderApi, "sellers"),
        customerAll: joinUrl(localOrderApi, "customers/update-all"),
        markOrderAsDelivered: (id: string, status: DeliveryStatus) =>
            joinUrl(localOrderApi, `deliveryorders/updatestatus/${id}/status/${status}`),
        orderPickingSetBincode: joinUrl(localOrderApi, `orderpickings/setbincode`),
        orderPickingSetCompleted: joinUrl(localOrderApi, `orderpickings/setcompleted`),
        orderPickingUpdateBin: joinUrl(localOrderApi, `orderpickings/updatebin`),
        invoiceNumeration: joinUrl(localOrderApi, `orderinvoices/numeration`),
    },
    delete: {
        order: (id: string) => joinUrl(localOrderApi, `orders/${id}`),
        invoiceNumeration: (id: string) => joinUrl(localOrderApi, `orderinvoices/numerations/${id}`),
    },
};

export const productEndpoint = {
    get: {
        product: (id: string) => joinUrl(api, `product/products/${id}/`),
        warehouseMovement: (id: string) => joinUrl(api, `product/warehousemovements/${id}/`),
        productListExcelByAccount: (accountId: string) => joinUrl(api, `product/products/download/excel/account/${accountId}/`),
        productListExcelByWarehouse: (warehouseId: string) => joinUrl(api, `product/products/download/excel/warehouse/${warehouseId}/`),
        productCatalogByAccount: (accountId: string) => joinUrl(api, `product/productcatalog/account/${accountId}/`),
        warehouseLocationsByWarehouse: (warehouseId: string) => joinUrl(api, `product/warehouselocations/warehouse/${warehouseId}`),
        warehousesByAccount: (accountId: string) => joinUrl(api, `product/warehouses/account/${accountId}`),
        warehousesMovementsByAccount: (accountId: string) => joinUrl(api, `product/warehousemovements/account/${accountId}`),
        warehouseProductByBarcode: (accountId: string, warehouseId: string, barcode: string) =>
            joinUrl(api, `product/warehouseproducts/account/${accountId}/warehouse/${warehouseId}/barcode/${barcode}`),
        productByBarcode: (accountId: string, barcode: string) => joinUrl(api, `product/products/account/${accountId}/barcode/${barcode}`),
        companiesByAccount: (accountId: string) => joinUrl(api, `product/companies/account/${accountId}`),
        warehouse: (id: string) => joinUrl(api, `product/warehouses/${id}`),
        warehouseStockValue: (warehouseId: string) => joinUrl(api, `product/warehouses/stockvalue/${warehouseId}`),

        categories: joinUrl(api, "product/categories"),
        customCategories: (accountId: string) => joinUrl(api, `product/categories/account/${accountId}`),
        inventoryAdjustmentByAccount: (accountId: string) => joinUrl(api, `product/inventoryadjustments/account/${accountId}`),
        inventoryAdjustmentDetailByCode: (code: string) => joinUrl(api, `product/inventoryadjustments/details/code/${code}`),
        taxesByAccount: (accountId: string) => joinUrl(api, `product/taxes/account/${accountId}`),
        discountsByAccount: (accountId: string) => joinUrl(api, `product/discounts/account/${accountId}`),
        inventoryAdjustmentById: (id: string) => joinUrl(api, `product/inventoryadjustments/${id}`),
        inventoryAdjustmentFinalize: (id: string) => joinUrl(api, `product/inventoryadjustments/finalize/${id}`),
        warehouseProductSummaryByProduct: (id: string) => joinUrl(api, `product/productsummary/warehouseproduct/${id}`),
        warehouseProductLocationSummaryByProduct: (id: string) => joinUrl(api, `product/productsummary/warehouseproductlocation/${id}`),
    },
    post: {
        warehouseMovement: joinUrl(api, "product/warehousemovements"),
        warehouseProductLocationMovement: joinUrl(api, "product/warehouselocations/productmovement"),
        warehouseProductLocationAdjustment: joinUrl(api, "product/warehouselocations/stockadjustment"),
        warehouseProductLocationAddProduct: joinUrl(api, "product/warehouselocations/addproduct"),
        productSearchQuery: joinUrl(api, "product/products/account/querysearch"),
        warehouseProductSearchQuery: joinUrl(api, "product/warehouseproducts/querysearch"),
        warehouseProductLocationSearchQuery: joinUrl(api, "product/warehouselocations/products/querysearch"),
        product: joinUrl(api, "product/products/updateall"),
        warehouse: joinUrl(api, "product/warehouses"),
        warehouseLocationMultipleCreation: joinUrl(api, "product/warehouselocations/multiplecreation"),
        warehouseLocationQuerySearch: joinUrl(api, `product/warehouselocations/querysearch`),
        warehouseLocationDeleteMultiple: joinUrl(api, `product/warehouselocations/deletemultiple`),
        productCatalog: joinUrl(api, "product/productCatalog"),
        categories: joinUrl(api, "product/categories"),
        customCategory: joinUrl(api, "product/categories/custom"),
        customCategoryUpdateImage: joinUrl(api, "product/categories/custom/updateimage"),
        productImagesCreateList: joinUrl(api, "product/productimages/uploadlist"),
        deleteProductImagesList: joinUrl(api, "product/productimages/removelist"),
        changeStatus: joinUrl(api, "product/products/changestatus"),
        inventoryAdjustment: joinUrl(api, "product/inventoryadjustments"),
        inventoryAdjustmentDetailsQuerySearch: joinUrl(api, "product/inventoryadjustments/querysearch"),
        inventoryAdjustmentQuerySearch: joinUrl(api, "product/inventoryadjustments/adjustment/querysearch"),
        inventoryAdjustmentDetail: joinUrl(api, "product/inventoryadjustments/detail"),
        inventoryProductAdjustment: joinUrl(api, "product/inventoryadjustments/productadjustment"),
        tax: joinUrl(api, "product/taxes"),
        discount: joinUrl(api, "product/discounts"),
        importProduct: joinUrl(api, "product/products/importproducts"),
        productImportGetOrderProducts: joinUrl(api, "product/products/importfile/orderproducts"),
        customCategorySearch: joinUrl(api, "product/categories/custom/querysearch"),
    },
    put: {
        categoryName: joinUrl(api, `product/api/categories/updatename`),
        warehouseMovementDetails: joinUrl(api, `product/warehousemovements/details`),
    },
    delete: {
        category: joinUrl(api, `product/api/categories/`),
        customCategory: (id: string) => joinUrl(api, `product/categories/custom/${id}`),
        warehouse: (id: string) => joinUrl(api, `product/warehouses/${id}`),
        warehouseLocationMultple: joinUrl(api, `product/warehouselocations/deletemultiple`),
        inventoryAdjustmentById: (id: string) => joinUrl(api, `product/inventoryadjustments/${id}`),
    },
};

export const purchaseEndpoint = {
    get: {
        purchaseByidAll: (id: string) => joinUrl(api, `purchase/purchases/all/${id}`),
        supplier: (id: string) => joinUrl(api, `purchase/suppliers/${id}`),
        suppliersByAccount: (accountId: string) => joinUrl(api, `purchase/suppliers/account/${accountId}`),
        purchasesPages: (req: IPaginationRequest) => joinUrl(api, `purchase/purchases/` + getPageRoute(req)),
    },
    post: {
        supplier: joinUrl(api, `purchase/suppliers`),
        purchaseAll: joinUrl(api, `purchase/purchases/updateall`),
        singlePayment: joinUrl(api, `purchase/payments`),
        purchaseSearchQuery: joinUrl(api, `purchase/purchases/querysearch`),
        deleteOrder: joinUrl(api, `purchase/purchases/deleteorder`),
    },
    put: {
        supplierAll: joinUrl(api, "purchase/suppliers/update-all"),
    },
};
export const accountEndpoint = {
    get: {
        expenseById: (id: string) => joinUrl(api, `account/expenses/${id}`),
        expenseCategoriesByAccount: (accountId: string) => joinUrl(api, `account/expensecategories/account/${accountId}`),
        accountUserWithAccount: (accountuserId: string) => joinUrl(api, `account/accountusers/useraccount/${accountuserId}`),
        accountUserById: (accountuserId: string) => joinUrl(api, `account/accountusers/${accountuserId}`),
        accounts: joinUrl(api, `account/accounts`),
        cashRegisters: (accountId: string) => joinUrl(api, `account/cashregisters/account/${accountId}`),
        cashRegisterLogsByCash: (cashRegisterId: string) => joinUrl(api, `account/cashregisters/logs/cashregister/${cashRegisterId}`),
        cashRegisterLogActive: (cashRegisterId: string) => joinUrl(api, `account/cashregisters/logs/active/${cashRegisterId}`),
        account: joinUrl(api, `account/accounts`),
        accountUserPassValidation: (accountId: string, pass: string) =>
            joinUrl(api, `account/accounts/accountuseradmin/validation/account/${accountId}/userpass/${pass}`),
        exceptions: joinUrl(api, `account/exceptions`),
        organizations: joinUrl(api, `account/organizations`),
        organizationsByUser: (accountuserId: string) => joinUrl(api, `account/organizations/user/${accountuserId}`),
        organizationsById: (id: string) => joinUrl(api, `account/organizations/${id}`),
        organizationsByAccount: (accountId: string) => joinUrl(api, `account/organizations/account/${accountId}`),
        accountUsersByAccount: (accountId: string) => joinUrl(api, `account/accountusers/account/${accountId}`),
    },
    post: {
        clientsettings: joinUrl(api, "account/accounts/clientsettings"),
        cashRegister: joinUrl(api, "account/cashregisters"),
        accountUser: joinUrl(api, "account/accountusers"),
        expense: joinUrl(api, "account/expenses"),
        expenseCategory: joinUrl(api, "account/expensecategories"),
        expenseSearchQuery: joinUrl(api, "account/expenses/account/querysearch"),
        companyImage: (type: CompanyImageTypes) => joinUrl(api, `account/accounts/company/uploadimage/${type}`),
        organizationImage: joinUrl(api, `account/organizations/uploadimage`),
        accountUserImage: joinUrl(api, `account/accountusers/uploadimage`),
        expenseImage: joinUrl(api, `account/expenses/uploadimage`),
        newAccountCompany: joinUrl(api, `account/accountusers/newaccountcompany`),
        updatepermissions: joinUrl(api, `account/accountusers/updatepermissions`),
        organization: joinUrl(api, `account/organizations`),
        organizationEcommerceCategory: joinUrl(api, `account/organizations/ecommercehomecategory`),
        organizationEcommerceBanner: joinUrl(api, `account/organizations/ecommerceadvertisingbanner`),
        cashRegisterEntryQuerySearch: joinUrl(api, `account/cashregisters/entries/querysearch`),
        cashRegisterEntry: joinUrl(api, `account/cashregisters/newentry`),
    },
    put: {
        accountUser: joinUrl(api, `account/accountusers`),
        updateCompanyName: (accountId: string, name: string) =>
            joinUrl(api, `account/accounts/updatecompanyname/account/${accountId}/companyname/${name}`),
        organization: joinUrl(api, `account/organizations`),
        organizationName: joinUrl(api, `account/organizations/updatename`),
        organizationAddress: joinUrl(api, `account/organizations/updateaddress`),
        organizationWarehosue: joinUrl(api, `account/organizations/updatewarehouse`),
        organizationInvoiceDesign: joinUrl(api, `account/organizations/updateinvoicedesign`),
        putCashRegisterStartLog: joinUrl(api, `account/cashregisters/logs/start`),
        putCashRegisterFinishLog: joinUrl(api, `account/cashregisters/logs/finish`),
        cashRegisterMovement: joinUrl(api, `account/cashregisters/movement`),
    },
    delete: {
        deleteExpenseList: (accountId: string, ids: string) => joinUrl(api, `account/expenses/account/${accountId}/ids/${ids}`),
        organizationEcommerceHomeCategory: (id: string) => joinUrl(api, `account/organizations/ecommercehomecategory/${id}`),
        organizationEcommerceBanner: (id: string) => joinUrl(api, `account/organizations/ecommerceadvertisingbanner/${id}`),
        accountUser: (id: string) => joinUrl(api, `account/accountusers/${id}`),
    },
};

export const digitalInvoiceEndpoint = {
    delete: {
        deleteCompanyConfiguration: (id: string) => joinUrl(api, `digitalinvoice/CompanyConfiguration/${id}`),
    },
    get: {
        getCompanyConfiguration: (id: string) => joinUrl(api, `digitalinvoice/CompanyConfiguration/${id}`),
        getMasterData: (id: string) => joinUrl(api, `digitalinvoice/MasterData/${id}`),
        getMasterDataTypes: joinUrl(api, `digitalinvoice/MasterDataTypes`),
    },
    post: {
        postCompanyConfiguration: joinUrl(api, `digitalinvoice/CompanyConfiguration`),
        postDigitalInvoice: joinUrl(api, `digitalinvoice/DigitalInvoice`),
    },
    put: {
        putCompanyConfiguration: joinUrl(api, `digitalinvoice/CompanyConfiguration`),
    },
};

export const statisticsEndpoint = {
    post: {
        globalSummary: joinUrl(api, `statistics/statistics/globalsummary`),
        orderAmountDayGroup: joinUrl(api, `statistics/statistics/orderamount/daygroup`),
        orderPurchaseMonthGroup: joinUrl(api, `statistics/statistics/ordersummary/monthgroup`),
        customCategoryGroup: joinUrl(api, `statistics/statistics/ordersummary/categorygroup`),
        getBestCustomers: joinUrl(api, `statistics/statistics/ordersummary/bestcustomers`),
        getCustomersHigherDebt: joinUrl(api, `statistics/statistics/ordersummary/customershigherdebt`),
        getBestChannels: joinUrl(api, `statistics/statistics/ordersummary/bestchannels`),
        productSales: joinUrl(api, `statistics/statistics/productsales`),
        lastProductSales: joinUrl(api, `statistics/statistics/productsummary/lastproducts`),
    },
};

export const permissionEndpoint = {
    get: {
        permissionsByAccount: joinUrl(api, `permission/api/permissions/account`),
    },
};
export default { accountEndpoint, loginEndpoint };
